import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useAuth } from "../Authentication/AuthProvider";
import "./navbar.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { BrowserUtils } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';
import { endpoint,key } from "../util.js";
import Tooltip from '@mui/material/Tooltip';
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Info } from "@mui/icons-material";
import pfContext from "../../context/PfContext";
import tooltipsContent from './tooltips.json';



const ProfileDrawer = (prop) => {

	const { instance } = useMsal();
	const { logout } = useAuth();

    ///useEffect(() => {
	const  logOut1=()=>{
		logout({user:
			null});
			window.localStorage.setItem("email",null);	
        instance.logoutRedirect({
            account: instance.getActiveAccount(),
            onRedirectNavigate: () => !BrowserUtils.isInIframe()
        })
	}
    ///}, [ instance ]);

	return (
		<Box className="profileDrawerContainerDiv" sx={{ display: `${prop.display}` }}>
			<Button className="logOut" onClick={(e)=>logOut1()}>
				
					<p className="proflieText">Log Out</p>
				
			</Button>
		</Box>
	);
};

const Navigationbar = () => {
	const context = React.useContext(pfContext);
	const { user } = useAuth();
	const location = useLocation();
	const url = location.pathname.split("/")[1];
	const [isOpen, setIsOpen] = useState(true);
	const [display, setDisplay] = useState("none");
	const [activeLink, setActiveLink] = React.useState("finder");
	// const [showTooltips, setShowTooltips] = React.useState(false);

	const [userName, setUserName] = React.useState("");
	// const [buttonHoverStates, setButtonHoverStates] = React.useState({
	// 	finder: false,
	// 	effect: false,
	// 	target: false,}) 

	let [role, setRole] = React.useState("");
	let [email, setEmail] = React.useState("");
	const cwid=(user!=null && user.user_cwid!=null)?user.user_cwid:"";
	React.useEffect(()=>{
		setEmail((user!=null && user.email!=null)?user.email:"");
		setRole((user!=null && user.role!=null)?user.role:"")
		setUserName((user!=null && user.user_name!=null)?user.user_name:"")
	},[email])
	

	function callUserTracking(portal,CWID)
	{
		try {
			const getdata = async () => {
				//setLoader(true);
				const response = await axios({
					url: endpoint,
					method: "POST",
					headers: { "x-api-key": key },
					data: {
						query: `query MyQuery {
							 CaptureUserActivity(portlet_name: "${portal}", user_cwid: "${CWID}")
							}`,
					},
				}).then((response) => {
					if (
						response.data != undefined &&
						response.data.data != undefined &&
						response.data.data["CaptureUserActivity"] != null
					) {
						
						
					}
					
					
				});
			};

			getdata();
		} catch (error) {
			console.log(error);
			
		}
	}



	const handleClick = (e) => {
		e.preventDefault();
		setIsOpen((prev) => !prev);
		setDisplay(isOpen ? "block" : "none");
	};
	React.useEffect(() => {
		if (activeLink!="") {
			document.getElementById(activeLink).style.borderBottom = "#1A7AEA 2px solid";
			if(activeLink==="finder")
			{
				context.setIsGsmSelectAll(false);
				callUserTracking("Potential Finder",cwid);
			}

			else if(activeLink==="effect"){
				context.setIsGsmSelectAll(true);
				callUserTracking("Effect Planning",cwid);

			}

			// else if(activeLink==="target")
			// {
			// 	callUserTracking("Target Steering",cwid);
			// 	window.open("https://app.powerbi.com/groups/816ba5ee-5a3e-49a9-a4c0-a69d1f626333/reports/40472567-baba-4a29-8f80-b911b5bf0001/ReportSection89dedebcbdea2ab40b80?bookmarkGuid=Bookmark2d5dbd752ddd4e000146","_blank");

			// }
			
		}
	}, [activeLink]);

	const handleLinkClick = (e) => {
		const htmlElemnt = document.getElementsByClassName("navLinkContainer");
		//console.log(htmlElemnt);
		context.setIsBrandSelectAll(true);
				context.setIsLocationSelectAll(true);
				context.setIsProductSelectAll(true);
				context.setIsCoMSelectAll(true);
				context.setGsmNetwork([]);
				context.setLocation([]);
				context.setProduct([]);
				context.setCom([]);
				context.setPipeline([]);
		
		if (e.currentTarget.id === "target") {
			callUserTracking("Target Steering", cwid);
			window.open("https://app.powerbi.com/groups/816ba5ee-5a3e-49a9-a4c0-a69d1f626333/reports/40472567-baba-4a29-8f80-b911b5bf0001/ReportSection89dedebcbdea2ab40b80?bookmarkGuid=Bookmark2d5dbd752ddd4e000146", "_blank");
			e.preventDefault();
		} else if (e.currentTarget.id === "alert") {
			window.open("https://app.powerbi.com/groups/816ba5ee-5a3e-49a9-a4c0-a69d1f626333/reports/37ea9fa4-d255-403d-8902-774dba0644f8/ReportSection543523c8149543628f23", "_blank");
			e.preventDefault();
		} else if (e.currentTarget.id === "tpt") {
			window.open("https://app.powerbi.com/groups/5bb4f8bd-ab20-4ed0-8d07-653ca0c52e68/reports/6613cc16-c9af-4d2f-a82c-2533d1b245a7/ReportSectionc96c6f83aede47276b90?experience=power-bi", "_blank");
			e.preventDefault();
		} else {
			setActiveLink(e.currentTarget.id);
			for (let i = 0; i < htmlElemnt.length; i++) {
				htmlElemnt[i].style.borderBottom = "";
			}
		}
		//console.log("e.currentTarget.id",htmlElemnt);
		//links to Power BI above
	

		
		//document.getElementById(activeLink).style.borderBottom = "#1A7AEA 2px solid";
		
	};

	/*const HintsSwitch = ({ checked, onChange }) */
	  
	  const handleSwitchChange = (event) => {
		context.setIsChecked(event.target.checked);
		context.setShowTooltips(event.target.checked);
	  };
	const label = { inputProps: { "aria-label": "Switch demo" } }
		
	return (
		<AppBar position="fixed" className="navbar">
			<Container maxWidth="100%">
				<Toolbar disableGutters className="navbar">
					<Box display="flex">
					<Link to="/newPF">
						<IconButton size="large" aria-label="logo" className="logoContainer">
							<img src="../../AppAssets/logo.png" alt="true" className="companylogo" />
						</IconButton>
						</Link>
					</Box>
					<Box className="logoContainer">
						<p className="AppName">Inventory Steering Cockpit</p>
					</Box>
					{ (user!=null && user.access)?<>
					<Stack direction="row" spacing={2} sx={{ flexGrow: 1, justifyContent: "center" }}>
					<Link to="/finder">
					<Tooltip 
					title={
						<span style={{ fontSize: '16px' }}>
						  {tooltipsContent.finder}
						</span>
					  }
					open={context.showTooltips && context.buttonHoverStates==="finder"}
					>
						<span>
						<Button
							id="finder"
							name="Potential Finder"
							className="navLinkContainer"
							onClick={(e) => handleLinkClick(e)}
							onMouseEnter={() => context.setButtonHoverStates(("finder"))}
							onMouseLeave={() => context.setButtonHoverStates((""))}
						>
							<p className="navLink">Potential Finder</p>
						</Button>
						</span>
					</Tooltip>
					</Link>

					<Link to="/effect">
					<Tooltip 
					title={
						<span style={{ fontSize: '16px' }}>
						  {tooltipsContent.effect}
						</span>
					  } 
					open={context.showTooltips && context.buttonHoverStates==="effect"}>
						<span>
						<Button
							id="effect"
							name="Effect Planning"
							className="navLinkContainer"
							onClick={(e) => handleLinkClick(e)}
							onMouseEnter={() => context.setButtonHoverStates( ("effect"))}
							onMouseLeave={() => context.setButtonHoverStates((""))}
						>
							<p className="navLink">Effect Planning</p>
						</Button>
						</span>
					</Tooltip>
					</Link>

					<Link to="/target">
					<Tooltip 
					title={
						<span style={{ fontSize: '16px' }}>
						  {tooltipsContent.target}
						</span>
					  } 
					open={context.showTooltips && context.buttonHoverStates==="target"}>
						<span>
						<Button
							id="target"
							name="Target Steering"
							className="navLinkContainer"
							onClick={(e) => handleLinkClick(e)}
							onMouseEnter={() => context.setButtonHoverStates( ("target"))}
							onMouseLeave={() => context.setButtonHoverStates((""))}
						>
							<p className="navLink">Target Steering</p>
						</Button>
						</span>
					</Tooltip>
					</Link>

					<Link to="/tpt">
					<Tooltip 
					title={
						<span style={{ fontSize: '16px' }}>
						  {tooltipsContent.tpt}
						</span>
					  } 
					open={context.showTooltips && context.buttonHoverStates==="tpt"}>
						<span>
						<Button
							id="tpt"
							name="TPT Analytics"
							className="navLinkContainer"
							onClick={(e) => handleLinkClick(e)}
							onMouseEnter={() => context.setButtonHoverStates( ("tpt"))}
							onMouseLeave={() => context.setButtonHoverStates((""))}
						>
							<p className="navLink">TPT Analytics</p>
						</Button>
						</span>
					</Tooltip>
					</Link>

					<Link to="/alert">
					<Tooltip title={
						<span style={{ fontSize: '16px' }}>
						  {tooltipsContent.alert}
						</span>
					  }  
					  open={context.showTooltips && context.buttonHoverStates==="alert"}>
						<span>
						<Button
							id="alert"
							className="navLinkContainer"
							onClick={(e) => handleLinkClick(e)}
							onMouseEnter={() => context.setButtonHoverStates("alert")}
							onMouseLeave={() => context.setButtonHoverStates("")}
						>
							<p className="navLink">Alert & Analysis</p>
						</Button>
						</span>
					</Tooltip>
					</Link>
						{ (
				role &&
				(role.toLowerCase() == "admin"))?
						<>	
						<Link to="/userManage">
						<Tooltip 
						title={
							<span style={{ fontSize: '16px' }}>
							  {tooltipsContent.userManage}
							</span>
						  } 
						   open={context.showTooltips && context.buttonHoverStates==="admin"}>
							<span>
							<Button
								id="userManage"
								className="navLinkContainer"
								onClick={(e) => handleLinkClick(e)}
								onMouseEnter={() => context.setButtonHoverStates("admin")}
								onMouseLeave={() => context.setButtonHoverStates("")}
							>
								<p className="navLink">User Management</p>
							</Button>
							</span>
						</Tooltip>
						</Link>

						<Link to="/track">
						<Tooltip 
						title={
							<span style={{ fontSize: '16px' }}>
							  {tooltipsContent.track}
							</span>
						  } 
						   open={context.showTooltips && context.buttonHoverStates==="track"}>
							<span>
							<Button
								id="track"
								className="navLinkContainer"
								onClick={(e) => handleLinkClick(e)}
								onMouseEnter={() => context.setButtonHoverStates("track")}
								onMouseLeave={() => context.setButtonHoverStates("")}
							>
								<p className="navLink">User Tracking</p>
							</Button>
							</span>
						</Tooltip>
						</Link></>
						:""
						
						}

			<FormControlLabel
			style={{position:"relative"}}
              control={
                <Switch
                  {...label}
                  checked={context.isChecked}
                  onChange={handleSwitchChange}
                />
              }
              label={context.isChecked ? 'Tooltip On' : 'Tooltip Off'} 
            />

						
					</Stack>
					<Tooltip title={
						<span style={{ fontSize: '16px' }}>
						  {tooltipsContent.profileContainerDiv}
						</span>
					  } 
					   open={context.showTooltips && context.buttonHoverStates==="profileContainerDiv"}>
					<Box className="profileContainerDiv">
						<Button onClick={handleClick} className="AvatarLink"
						onMouseEnter={() => context.setButtonHoverStates("profileContainerDiv")}
						onMouseLeave={() => context.setButtonHoverStates("")}>
							<Avatar src=""></Avatar>
							<p className="proflieText">
								<b>
									{userName}
								
								</b>
								<br />
								<br />
								{role}
							</p>
							
							<span style={{ position: "absolute", right: "-3rem" }}>
								<img src="../../AppAssets/down.png" alt="true" />
							</span>
						</Button>
						<ProfileDrawer display={display} />
					</Box>
					</Tooltip>
					<Box className="boxFlex">
								<Tooltip title={
							<span style={{ fontSize: '16px' }}>
							  {tooltipsContent.info}
							</span>
						  }  >
								<div id="info" onMouseEnter={() => context.setButtonHoverStates("info")}
						          onMouseLeave={() => context.setButtonHoverStates("")}>
									<Info color="secondary"/>
								</div>
								</Tooltip>
					</Box>
					
					</>
					:""

					}
				</Toolbar>
			</Container>
		</AppBar>
	);
};
export default Navigationbar;
