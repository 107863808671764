import { useMsal } from '@azure/msal-react';
import * as React from 'react';
import axios from "axios";
import { useForm } from "react-hook-form";
import { useAuth } from "./Authentication/AuthProvider";
//import { useIsAuthenticated} from '@azure/msal-react';
import {  InteractionType } from "@azure/msal-browser";


import "../GlobalComponent/NavBar/navbar.css";
import UserAccessPage from '../Components/AccessPage/UserAccessPage';
import { useNavigate } from "react-router-dom";
import Loader from '../GlobalComponent/loader';
import { endpoint,key } from './util.js';

//let accessToken = "";
//let userEmailId="";
const LoginPage = () => {
    const [email,setEmail]=React.useState("");
  //  const [userEmail, setUserEmail]=React.useState("");
    let[accessToken,setAccessToken]=React.useState([]);
  //  let[error1,setError1]=React.useState([]);
   // const emialRef=useRef(null);
    const [loader,setLoader]=React.useState(false);
    const [isAccess,setIsAccess]=React.useState("");
    const { login,user,logout } = useAuth();
    //window.localStorage.setItem("user",null);
    const { instance, accounts, inProgress } = useMsal();
   window.localStorage.setItem("user",null);
    const isAuthenticated = accounts.length > 0;

    //const [user,setUser]=React.useState([]);
    const navigate = useNavigate();
  
    const { register, handleSubmit,setError, formState: { errors } } = useForm();

 
  function authenticate()
  {
    const msalInstance = instance;
    const msalAccounts = accounts;
    const msalInProgress = inProgress;
    
   // const isAuthenticated = accounts.length > 0;

    /*if (this.state.error.length > 0) {
        return;
    }*/

    const eventCallback = msalInstance.addEventCallback((message) => {
        if (!isAuthenticated && !accessToken) {
            const payload = message.payload ;
            const name = payload.account?.name ? payload.account?.name: "";

            accessToken = payload.accessToken;
            setEmail(name);
            //this.tryRefreshUserPermissions();
        }
    });

    const loginRequest = {
        scopes: ["User.Read"],
        account: msalAccounts[0]
    };

    if (!isAuthenticated && msalInProgress === InteractionType.None) {
        msalInstance.loginRedirect(loginRequest);
    }
    else if (isAuthenticated && accessToken) {
       // this.getembedUrl();
        msalInstance.removeEventCallback(eventCallback);
    }
   
    else if ( isAuthenticated && !accessToken  && msalInProgress === InteractionType.None) {
        setEmail(msalAccounts[0].username);

        // get access token silently from cached id-token
        /*msalInstance.acquireTokenSilent(loginRequest).then((response) => {

            accessToken = response.accessToken;
           // console.log("accessToken"+accessToken);
            setEmail(msalAccounts[0].name);

           // this.getembedUrl();
        }).catch((error) => {
            // Refresh access token silently from cached id-token
            // Makes the call to handleredirectcallback
            if (error.errorCode === "consent_required" || error.errorCode === "interaction_required" || error.errorCode === "login_required") {
                msalInstance.acquireTokenRedirect(loginRequest);
            }
            else if (error.errorCode === '429') {
                 setError1("Our Service Token Server (STS) is overloaded, please try again in sometime");
            }
            else {
                setError1("There was some problem fetching the access token" + error.toString())
            }
        });*/
    }
  }

 
        //handleSubmit1();
authenticate();
  

  const handleSubmit1 = (email) => {
   
    try{
         const getdata= async () => {
                  setLoader(true)   
             const response = await axios({
                 url: endpoint,
                 method: "POST",
                 headers: { "x-api-key": key},
                 data: {
                     query: `query MyQuery {
                          UserAccessRequest(User_Email:"${email}") {
                             user_name
                              access_rights
                              gsm_network_code
                              gsm_description
                              company_code
                              access
                              user_cwid
                              user_email
                              user_role
                          }
                         }`
                 }
             }).then(response=>{
                if(response.data!=undefined && response.data.data!=undefined  && response.data.data["UserAccessRequest"]!=null)
                 {
                     console.log(response.data.data['UserAccessRequest']);
                    // if(response.data.data['UserAccessRequest']!=null){
                     
                    const isAccess=response.data.data['UserAccessRequest']["access"]
                 // const isAccess = true;
                    //setIsAccess(isAccess)
                    setEmail(response.data.data['UserAccessRequest']["user_email"]);
                    window.localStorage.setItem("email",response.data.data['UserAccessRequest']["user_email"]);
                    login({
                        access: isAccess,
                        email:response.data.data['UserAccessRequest']["user_email"],
                        role:response.data.data['UserAccessRequest']["user_role"],
                        user_cwid:response.data.data['UserAccessRequest']["user_cwid"],
                        user_name:response.data.data['UserAccessRequest']["user_name"],
                        access_rights:response.data.data['UserAccessRequest']["access_rights"],
                        gsm_network_code:response.data.data['UserAccessRequest']["gsm_network_code"],
                        gsm_description:response.data.data['UserAccessRequest']["gsm_description"],
                        company_code:response.data.data['UserAccessRequest']["company_code"]
                      
                      });
                    if(isAccess)
                    {
                     setIsAccess(true);
                  //authenticate();
                   navigate("/finder");
                     
                     }
                    else
                    {
                    setIsAccess(false);
                    window.localStorage.setItem("email",null);
                    }
                  
                    // }
                    setLoader(false)
                 }
                 else
                 {
                     setLoader(false);
                     logout({user:
                         null});

                 }
               
             })
         }
     
        getdata();
        }
        catch(error)
        {
         setLoader(false)
        logout({user:
         null});
         
            console.log(error);
           // setLoader(false);
        }
   };



 
   
  React.useEffect(() => {

    const cancelToken=axios.CancelToken.source();
    if (isAuthenticated){
        const email1= accounts[0].username;
        handleSubmit1(email1);
    
    } 
    else
    {
        window.localStorage.setItem("email",null);
    }

    return ()=>{
        cancelToken.cancel();
    }

 }, [isAuthenticated]);
  return (
    loader ? <Loader/> :
      <div>
        {/* <AppBar position="fixed" className="navbar">
			<Container maxWidth="100%">
				<Toolbar disableGutters className="navbar">
					<Box display="flex">
						<IconButton size="large" aria-label="logo" className="logoContainer">
							<img src="../../AppAssets/logo.png" alt="true" className="companylogo" />
						</IconButton>
					</Box>
					<Box className="logoContainer">
						<p className="AppName">Inventory Steering Cockpit</p>
					</Box>
			
				</Toolbar>
			</Container>
		</AppBar> */}
        
       {  
        (user!=null && !isAccess)? <UserAccessPage/>
       :""
        }
       
          
          
        </div>
    
    );


}

export default LoginPage;


