import * as React from "react";
import DraggbleFilter from "../../GlobalComponent/DraggableList/DraggbleFilter";
import "./customizeNorm.css";
import {
  getNormsQuery,
  checkDuplicacy,
  getPreviewNormsQuery,
  resetCustomVariable,
  addCustomVariable,
  getSaveNormsQuery,
  setPreGsm,
  loadScenarioQuery,
  getNewPreviewCustomQuery,
  retainChangedValueinTree,
  callAPI,
  setStatetoContext,
} from "./GraphQueryNew";
import axios from "axios";
import Loader from "../../GlobalComponent/loader";
import {
  Input,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  Button,
  Box,
  Stack,
} from "@mui/material";
import ModalPopup from "../../GlobalComponent/ModalPopup";
import ErrorDailog from "./ErrorDailog";
import SaveModal from "./SaveModal";

import { useAuth } from "../../GlobalComponent/Authentication/AuthProvider";
import { Tooltip } from "@material-ui/core";
import pfContext from "../../context/PfContext";
import tooltipsContent from "../../GlobalComponent/NavBar/tooltips.json";

const CustomizeNormContainer = (props) => {
  const { user } = useAuth();

  const context = React.useContext(pfContext);
  
  let {gsmCode,locCode,comCode,proCode,pipCode}=context.getFilterValues();
  
  const [FilterSub, setFilterSub] = React.useState(['Brand_Sel_All: true, Brand_Values: "", CoM_Sel_All: true, CoM_Values: "", CWID: "EFXUO", GSM_Sel_All: false, Location_Sel_All: true, Location_Values: "", Product_Sel_All: true, Product_Values: "", GSM_Values: "", '])

  const cwid = user != null && user.user_cwid != null ? user.user_cwid : "";
  const [isNormDisplay, setIsNormDisplay] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [masterNormData, setMasterNormData] = React.useState([]);

  
  //const [customNormData, setCustomNormData] = React.useState([]);
  const [filterValues, setFilterValues] = React.useState([
    "GSM",
    "Pipeline",
    "Location",
    "CoM",
    "Material",
  ]);
  const [selectedFilters, setSelectedFilters] = React.useState([
    "GSM",
    "Pipeline",
    "Location",
    "CoM",
    "Material",
  ]);
  const [secondList, setSecondList] = React.useState([]);
  const [isFilterChange, setIsFilterChange] = React.useState(false);
  const treeRef = React.useRef();
  let [clickParent, setClickParent] = React.useState([]);
  const [showError, SetShowError] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const [showSave, SetShowSave] = React.useState(false);
  const [saveMsg, setSaveMsg] = React.useState("");
  const [isResetClicked, setIsResetClicked] = React.useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = React.useState(false);

  const [isPreviewClicked, setIsPreviewClicked] = React.useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = React.useState(false);
  const [hasMoreItems, setHasMoreItems] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [parentNode, setParentNode] = React.useState([]);
  const [subChild1, setSubChild1] = React.useState([]);
  const [subChild2, setSubChild2] = React.useState([]);
  const [subChild3, setSubChild3] = React.useState([]);
  const [subChild4, setSubChild4] = React.useState([]);

  const [masterParentNode, setMasterParentNode] = React.useState([]);
  const [masterSubChild1, setMasterSubChild1] = React.useState([]);
  const [masterSubChild2, setMasterSubChild2] = React.useState([]);
  const [masterSubChild3, setMasterSubChild3] = React.useState([]);
  const [masterSubChild4, setMasterSubChild4] = React.useState([]);
  const [uomButton, setUomButton] = React.useState("Value");
  const [sortButton, setSortButton] = React.useState("Value");
  const [normUom, setNormUom] = React.useState(0);
  const [resetModalShow, setResetModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);

  const [gsm, setGsm] = React.useState(
    context.gsmNetwork?.map((item) => item.code).toString()
  );
  const [bran, setBran] = React.useState("");
  const [co, setCo] = React.useState("");
  const [loc, setLoc] = React.useState("");
  const [pro, setPro] = React.useState("");
  //const selectedMonth=props.monthYear;
  const [nodesEdited, setNodesEdited] = React.useState([]);
  const [isPreviewDisabled, setIsPreviewDisabled] = React.useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = React.useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = React.useState(true);
  //const [tabName,setTabName]=React.useState("");
  const [nodeAfterPreview, setNodeAfterPreview] = React.useState([]);
  const [isClicked, setIsClicked] = React.useState("");
  const [newFilterVal, setNewFilterVal] = React.useState([]);
  const role = user != null && user.role != null ? user.role : "";
  const currentUser = user != null && user.email != null ? user.email : "";

  let userGsm =
    user != null && user.gsm_network_code != null ? user.gsm_network_code : "";

  const parentDivStyle = () => ({
    display: "flex",
    width: "100%",
    position: "relative",
    marginLeft: "2rem",
  });

  const ulStyle = () => ({
    listStyle: "none",
  });

  const liStyle = () => ({
    display: "inline-flex",
    position: "relative",
    cursor: "pointer",
    height: "35px",
  });

  const divStyle = () => ({
    width: "20rem",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    fontFamily: "Lato-Medium",
    fontSize: "1.4rem",
    justifyContent: "center",
    position: "relative",
    flexDirection: "column",
  });

  const spanStyle = () => ({
    width: "12rem",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    fontFamily: "Lato-Medium",
    fontSize: "1.4rem",
    justifyContent: "end",
    position: "relative",
  });

  const leftBorderSpanStyle = () => ({
    position: "relative",
  });
  const treeSpanStyle = () => ({
    fontFamily: "Lato-Medium",
    fontSize: "1.4rem",
    color: "#000000",
    display: "flex",
    alignItems: "center",
  });

  const handleExpandButton = () => {
    setIsNormDisplay(true);
  };

  React.useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (sortButton !== "" && isNormDisplay) {
      setPageNumber(1);
      if (
        props.tableName === "" ||
        (props.tableName === undefined && !props.isLoadSubmit)
      ) {
        getDefineNormData(1, cancelToken.token);
      } else {
        load_Preview(1, props.tableName, cancelToken.token);
      }
    }

    return () => {
      cancelToken.cancel();
    };
  }, [sortButton]);

  React.useEffect(() => {
    if (props.isLoadData && props.tableName === "") {
      setIsNormDisplay(false);
      setClickParent([]);
      setIsClicked("");
      setFilterSub(`Brand_Sel_All: ${context.isBrandSelectAll}, 
      Brand_Values: "${pipCode}", CoM_Sel_All: ${context.isCoMSelectAll}, CoM_Values: "${comCode}", 
      CWID: "${cwid}", GSM_Sel_All: false, Location_Sel_All: ${context.isLocationSelectAll},
     Location_Values: "${locCode}", Product_Sel_All: ${context.isProductSelectAll}, Product_Values: "${proCode}",
      GSM_Values: "${gsmCode}", `);

      

    } else if (props.isLoadData && props.tableName !== "") {
      setIsNormDisplay(false);
      setFilterSub(`Brand_Sel_All: ${context.isBrandSelectAll}, 
      Brand_Values: "${pipCode}", CoM_Sel_All: ${context.isCoMSelectAll}, CoM_Values: "${comCode}", 
      CWID: "${cwid}", GSM_Sel_All: false, Location_Sel_All: ${context.isLocationSelectAll},
     Location_Values: "${locCode}", Product_Sel_All: ${context.isProductSelectAll}, Product_Values: "${proCode}",
      GSM_Values: "${gsmCode}", `);
      //setClickParent([]);
      //setNodesEdited([]);
      //setMasterNormData([]); 
    }
  }, [props.isLoadData]);
  React.useEffect(() => {
    if (context.gsmNetwork.length > 0 && props.tableName === "") {
      setGsm(context.gsmNetwork?.map((item) => item.code).toString());
      setClickParent([]);
      setIsClicked("");
    } else {
      setGsm("");
      setIsDeleteDisabled(true);
      setIsPreviewDisabled(true);
      setIsSaveDisabled(true);
    }
  }, [context.gsmNetwork]);

  React.useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (isClicked !== "") {
      setPageNumber(1);
      if (
        props.tableName === "" ||
        (props.tableName === undefined && !props.isLoadSubmit)
      ) {
        getDefineNormData(1, cancelToken.token);
      } else {
        load_Preview(1, props.tableName, cancelToken.token);
      }
    }
    return () => {
      cancelToken.cancel();
    };
  }, [isClicked]);

  React.useEffect(() => {
    if (newFilterVal.length > 0) {
      setFilterValues(newFilterVal);
      setSelectedFilters(newFilterVal);
    }
  }, [newFilterVal]);

  const handleCollapsibleButton = () => {
    setIsNormDisplay(false);
  };

  const showErrorModal = React.useCallback(
    (errMsg) => {
      // debugger
      setErrMsg(errMsg);

      SetShowError((prev) => !prev);
    },
    [showError]
  );

  const showSaveModal = React.useCallback(
    (errMsg) => {
      setSaveMsg(errMsg);
      SetShowSave((prev) => !prev);
    },
    [showSave]
  );

  const changeLoaderState = React.useCallback(() => {
    setLoader((loader) => !loader);
  }, [loader]);

  function resetPropsBasedOnFilter(value, index) {
    if (selectedFilters.length > 1) {
      if (selectedFilters[index] == "Pipeline") setBran(value.split("_")[0]);
      else if (selectedFilters[index] == "CoM") setCo(value.split("_")[0]);
      else if (selectedFilters[index] == "Location")
        setLoc(value.split("_")[0]);
      else if (selectedFilters[index] == "Material")
        setPro(value.split("_")[0]);
      // debugger
    }
  }

  /* function used to get the tree Node value */
  const getDefineNormData = (page, token) => {
    if (page != null) setPageNumber(page);
    let gms = gsm;
    setLoader(true);
    //changeLoaderState();
    const page1 = page != null ? page : pageNumber;
    if (masterNormData != null) {
      gms = gsm == "" ? setPreGsm(masterNormData) : gsm;
    }
    const normData_Query = getNormsQuery(
      selectedFilters,
      gms,
      bran,
      co,
      loc,
      pro,
      page1,
      props.monthYear,
      cwid,
      sortButton,
      FilterSub
    );

    callAPI(normData_Query, token)
      .then((response) => {
        if (
          response.data !== undefined &&
          response.data["NORMCustomizedView"] != undefined &&
          response.data["NORMCustomizedView"] !== null
        ) {
          let data = [];
          data = response.data["NORMCustomizedView"];

          setHasMoreItems(false);
          setMasterNormData(data);

          if (
            data["Parent"] != null &&
            (data["Children_01"] == null || data["Children_01"].length == 0)
          ) {
            //if(pageNumber>1)
            const data1 = addCustomVariable(data["Parent"], "A");
            setParentNode(data1);
            //else
            //setParentNode(data['Parent']);
            setSubChild1([]);
            setSubChild2([]);
            setSubChild3([]);
            setSubChild4([]);

            setMasterParentNode([...masterParentNode, ...data1]);
            setMasterSubChild1([]);
            setMasterSubChild2([]);
            setMasterSubChild3([]);
            setMasterSubChild4([]);
          } else if (
            data["Children_01"] != null &&
            data["Children_02"] == null
          ) {
            // debugger
            const data1 = addCustomVariable(data["Children_01"], "B");
            setParentNode(addCustomVariable(data["Parent"]), "A");
            setSubChild1(data1);
            setSubChild2([]);
            setSubChild3([]);
            setSubChild4([]);

            setMasterParentNode(addCustomVariable(data["Parent"], "A"));
            setMasterSubChild1(data1);
            setMasterSubChild2([]);
            setMasterSubChild3([]);
            setMasterSubChild4([]);
          } else if (
            data["Children_02"] != null &&
            data["Children_03"] == null
          ) {
            const data1 = addCustomVariable(data["Children_02"], "C");
            setParentNode(addCustomVariable(data["Parent"]), "A");
            setSubChild1(addCustomVariable(data["Children_01"], "B"));
            setSubChild2(data1);
            setSubChild3([]);
            setSubChild4([]);

            setMasterParentNode(addCustomVariable(data["Parent"], "A"));
            setMasterSubChild1(addCustomVariable(data["Children_01"], "B"));
            setMasterSubChild2(data1);
            setMasterSubChild3([]);
            setMasterSubChild4([]);
          } else if (
            data["Children_03"] != null &&
            data["Children_04"] == null
          ) {
            const data1 = addCustomVariable(data["Children_03"], "D");
            setParentNode(addCustomVariable(data["Parent"], "A"));
            setSubChild1(addCustomVariable(data["Children_01"], "B"));
            setSubChild2(addCustomVariable(data["Children_02"], "C"));
            setSubChild3(data1);
            setSubChild4([]);

            setMasterParentNode(addCustomVariable(data["Parent"], "A"));
            setMasterSubChild1(addCustomVariable(data["Children_01"], "B"));
            setMasterSubChild2(addCustomVariable(data["Children_02"], "C"));
            setMasterSubChild3(data1);
            setMasterSubChild4([]);
          } else if (data["Children_04"] != null) {
            const data1 = addCustomVariable(data["Children_04"], "E");
            setParentNode(addCustomVariable(data["Parent"], "A"));
            setSubChild1(addCustomVariable(data["Children_01"], "B"));
            setSubChild2(addCustomVariable(data["Children_02"], "C"));
            setSubChild3(addCustomVariable(data["Children_03"], "D"));
            setSubChild4(data1);

            setMasterParentNode(addCustomVariable(data["Parent"], "A"));
            setMasterSubChild1(addCustomVariable(data["Children_01"], "B"));
            setMasterSubChild2(addCustomVariable(data["Children_02"], "C"));
            setMasterSubChild3(addCustomVariable(data["Children_03"], "D"));
            setMasterSubChild4(data1);
          }
        }
        setIsFilterChange(false);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error("error", error);
      });
  };

  /*function used to get next set of data in tree when clicked on Load More button */
  const getDefineNormData1 = (page, token) => {
    let gms = gsm;
    setLoader(true);
    const page1 = page != null ? page : pageNumber;
    if (masterNormData != null) {
      gms = gsm == "" ? setPreGsm(masterNormData) : gsm;
    }
    const normData_Query = getNormsQuery(
      selectedFilters,
      gms,
      bran,
      co,
      loc,
      pro,
      page1,
      props.monthYear,
      cwid,
      sortButton,
      FilterSub
    );
    callAPI(normData_Query, token)
      .then((response) => {
        if (
          response.data !== undefined &&
          response.data["NORMCustomizedView"] !== undefined &&
          response.data["NORMCustomizedView"] !== null
        ) {
          let data = [];
          data = response.data["NORMCustomizedView"];
          //if(data['Parent']==null || data['Parent'].length==0)
          setHasMoreItems(false);
          setMasterNormData(data);
          if (
            data["Parent"] != null &&
            (data["Children_01"] == null || data["Children_01"].length == 0)
          ) {
            //if(pageNumber>1)
            const data1 = addCustomVariable(data["Parent"], "A");
            setParentNode([...parentNode, ...data1]);
            //else
            //setParentNode(data['Parent']);
            setSubChild1([]);
            setSubChild2([]);
            setSubChild3([]);
            setSubChild4([]);

            setMasterParentNode([...masterParentNode, ...data1]);
            setMasterSubChild1([]);
            setMasterSubChild2([]);
            setMasterSubChild3([]);
            setMasterSubChild4([]);
          } else if (
            data["Children_01"] != null &&
            data["Children_02"] == null
          ) {
            // debugger
            const data1 = addCustomVariable(data["Children_01"], "B");
            setParentNode(addCustomVariable(data["Parent"], "A"));
            setSubChild1([...subChild1, ...data1]);
            setSubChild2([]);
            setSubChild3([]);
            setSubChild4([]);

            setMasterParentNode(addCustomVariable(data["Parent"], "A"));
            setMasterSubChild1([...masterSubChild1, ...data1]);
            setMasterSubChild2([]);
            setMasterSubChild3([]);
            setMasterSubChild4([]);
          } else if (
            data["Children_02"] != null &&
            data["Children_03"] == null
          ) {
            const data1 = addCustomVariable(data["Children_02"], "C");
            setParentNode(addCustomVariable(data["Parent"], "A"));
            setSubChild1(addCustomVariable(data["Children_01"], "B"));
            setSubChild2([...subChild2, ...data1]);
            setSubChild3([]);
            setSubChild4([]);

            setMasterParentNode(addCustomVariable(data["Parent"], "A"));
            setMasterSubChild1(addCustomVariable(data["Children_01"], "B"));
            setMasterSubChild2([...masterSubChild2, ...data1]);
            setMasterSubChild3([]);
            setMasterSubChild4([]);
          } else if (
            data["Children_03"] != null &&
            data["Children_04"] == null
          ) {
            const data1 = addCustomVariable(data["Children_03"], "D");
            setParentNode(addCustomVariable(data["Parent"], "A"));
            setSubChild1(addCustomVariable(data["Children_01"], "B"));
            setSubChild2(addCustomVariable(data["Children_02"], "C"));
            setSubChild3([...subChild3, ...data1]);
            setSubChild4([]);

            setMasterParentNode(addCustomVariable(data["Parent"], "A"));
            setMasterSubChild1(addCustomVariable(data["Children_01"], "B"));
            setMasterSubChild2(addCustomVariable(data["Children_02"], "C"));
            setMasterSubChild3([...masterSubChild3, ...data1]);
            setMasterSubChild4([]);
          } else if (data["Children_04"] != null) {
            const data1 = addCustomVariable(data["Children_04"], "E");
            setParentNode(addCustomVariable(data["Parent"], "A"));
            setSubChild1(addCustomVariable(data["Children_01"], "B"));
            setSubChild2(addCustomVariable(data["Children_02"], "C"));
            setSubChild3(addCustomVariable(data["Children_03"], "D"));
            setSubChild4([...subChild4, ...data1]);

            setMasterParentNode(addCustomVariable(data["Parent"], "A"));
            setMasterSubChild1(addCustomVariable(data["Children_01"], "B"));
            setMasterSubChild2(addCustomVariable(data["Children_02"], "C"));
            setMasterSubChild3(addCustomVariable(data["Children_03"], "D"));
            setMasterSubChild4([...masterSubChild4, ...data1]);
          }

          setPageNumber(pageNumber + 1);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.error("error", error);
        setLoader(false);
      });
  };

  const load_Preview = (page, tableName1, token) => {
    let gms = gsm;
    setLoader(true);
    const page1 = page !== null ? page : pageNumber;
    if (masterNormData !== null) {
      gms = gsm == "" ? setPreGsm(masterNormData) : gsm;
    }
    let normData_Query = getNewPreviewCustomQuery(
      selectedFilters,
      gms,
      bran,
      co,
      loc,
      pro,
      page1,
      props.monthYear,
      cwid,
      tableName1,
      sortButton,
      FilterSub
    );
    callAPI(normData_Query, token)
      .then((response) => {
        if (
          response.data !== undefined &&
          response.data["LOAD_Preview"] !== undefined &&
          response.data["LOAD_Preview"] !== null
        ) {
          let data = [];
          data = response.data["LOAD_Preview"];
          setHasMoreItems(false);
          setMasterNormData(data);
          if (
            data["Parent"] !== null &&
            (data["Children_01"] == null || data["Children_01"].length == 0)
          ) {
            const data1 = addCustomVariable(data["Parent"], "A");
            setParentNode([...parentNode, ...data1]);

            setSubChild1([]);
            setSubChild2([]);
            setSubChild3([]);
            setSubChild4([]);

            setMasterParentNode([...masterParentNode, ...data1]);
            setMasterSubChild1([]);
            setMasterSubChild2([]);
            setMasterSubChild3([]);
            setMasterSubChild4([]);
          } else if (
            data["Children_01"] != null &&
            data["Children_02"] == null
          ) {
            // debugger
            const data1 = addCustomVariable(data["Children_01"], "B");
            setParentNode(addCustomVariable(data["Parent"], "A"));
            setSubChild1([...subChild1, ...data1]);
            setSubChild2([]);
            setSubChild3([]);
            setSubChild4([]);

            setMasterParentNode(addCustomVariable(data["Parent"], "A"));
            setMasterSubChild1([...masterSubChild1, ...data1]);
            setMasterSubChild2([]);
            setMasterSubChild3([]);
            setMasterSubChild4([]);
          } else if (
            data["Children_02"] != null &&
            data["Children_03"] == null
          ) {
            const data1 = addCustomVariable(data["Children_02"], "C");
            setParentNode(addCustomVariable(data["Parent"], "A"));
            setSubChild1(addCustomVariable(data["Children_01"], "B"));
            setSubChild2([...subChild2, ...data1]);
            setSubChild3([]);
            setSubChild4([]);

            setMasterParentNode(addCustomVariable(data["Parent"], "A"));
            setMasterSubChild1(addCustomVariable(data["Children_01"], "B"));
            setMasterSubChild2([...masterSubChild2, ...data1]);
            setMasterSubChild3([]);
            setMasterSubChild4([]);
          } else if (
            data["Children_03"] != null &&
            data["Children_04"] == null
          ) {
            const data1 = addCustomVariable(data["Children_03"], "D");
            setParentNode(addCustomVariable(data["Parent"], "A"));
            setSubChild1(addCustomVariable(data["Children_01"], "B"));
            setSubChild2(addCustomVariable(data["Children_02"], "C"));
            setSubChild3([...subChild3, ...data1]);
            setSubChild4([]);

            setMasterParentNode(addCustomVariable(data["Parent"], "A"));
            setMasterSubChild1(addCustomVariable(data["Children_01"], "B"));
            setMasterSubChild2(addCustomVariable(data["Children_02"], "C"));
            setMasterSubChild3([...masterSubChild3, ...data1]);
            setMasterSubChild4([]);
          } else if (data["Children_04"] != null) {
            const data1 = addCustomVariable(data["Children_04"], "E");
            setParentNode(addCustomVariable(data["Parent"], "A"));
            setSubChild1(addCustomVariable(data["Children_01"], "B"));
            setSubChild2(addCustomVariable(data["Children_02"], "C"));
            setSubChild3(addCustomVariable(data["Children_03"], "D"));
            setSubChild4([...subChild4, ...data1]);

            setMasterParentNode(addCustomVariable(data["Parent"], "A"));
            setMasterSubChild1(addCustomVariable(data["Children_01"], "B"));
            setMasterSubChild2(addCustomVariable(data["Children_02"], "C"));
            setMasterSubChild3(addCustomVariable(data["Children_03"], "D"));
            setMasterSubChild4([...masterSubChild4, ...data1]);
          }
          setPageNumber(pageNumber + 1);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.error("error", error);
        setLoader(false);
      });
  };

  React.useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (hasMoreItems) {
      if (props.tableName === "" || props.tableName === undefined)
        getDefineNormData1(pageNumber + 1, cancelToken.token);
      else load_Preview(pageNumber + 1, props.tableName, cancelToken.token);
    }
    return () => {
      cancelToken.cancel();
    };
  }, [hasMoreItems]);

  /*update reset Button state */
  const updateResetButtonState = () => {
    setIsResetClicked(true);
  };

  /* when reset Button click in customize section */
  React.useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (isResetClicked) {
      if (props.tableName !== "" && !props.isSaveDone) {
        setLoader(true);

        const resetQuery = `mutation MyMutation { RESET(CWID: "${cwid}"){T_F RESET}}`;
        callAPI(resetQuery, cancelToken.token)
          .then((response) => {
            if (response.data !== undefined && response.data["RESET"]["T_F"]) {
              //context.setIsResetContext(true);
              setClickParent([]);
              setNodesEdited([]);
              props.setIsSaveDone(false);
              setIsNormDisplay(false);
              setIsClicked("");
              setIsResetClicked(false);
              setGsm("");
              setMasterNormData([]);
              setResetModalShow(false);
              props.resetFilters();
            } else {
              setResetModalShow(false);
              setLoader(false);
            }
          })
          .catch((error) => {
            console.error(error);
            setLoader(false);
          });
      } else if (!props.isSaveDone) {
        if (masterNormData != null) {
          if (masterParentNode != null) {
            setParentNode(resetCustomVariable(masterParentNode));
          }
          if (masterSubChild1 != null) {
            setSubChild1(resetCustomVariable(masterSubChild1));
          }
          if (masterSubChild2 != null) {
            setSubChild2(resetCustomVariable(masterSubChild2));
          }
          if (masterSubChild3 != null) {
            setSubChild3(resetCustomVariable(masterSubChild3));
          }
          if (masterSubChild4 != null) {
            setSubChild4(resetCustomVariable(masterSubChild4));
          }
          setNodesEdited([]);
          setIsResetClicked(false);
          setResetModalShow(false);
          props.setIsSaveDone(false);
        }
      } else if (props.isSaveDone) {
        setClickParent([]);
        setNodesEdited([]);
        props.setIsSaveDone(false);
        setIsNormDisplay(false);
        setIsDeleteDisabled(true);
        setIsSaveDisabled(true);
        setIsClicked("");
        setIsResetClicked(false);
        setGsm("");
        setMasterNormData([]);
        setResetModalShow(false);
        props.resetFilters();
        setIsDeleteDisabled(true);
      }
    }
  }, [isResetClicked]);

  React.useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (props.isLoadSubmit) {
      setPageNumber(1);
      setLoader(true);
      callSecnario(props.scenarioName, cancelToken.token);
    }
    return () => {
      cancelToken.cancel();
    };
  }, [props.isLoadSubmit]);

  function callSecnario(name, token) {
    if (name != null) {
      props.setLoader(true);
      //setLoader(true);
      const LoadQuery = loadScenarioQuery(name, cwid);
      callAPI(LoadQuery, token)
        .then((response) => {
          if (
            response.data !== undefined &&
            response.data !== undefined &&
            response.data["CustomizedView_LoadPreviousScenario"]
          ) {
            props.setIsCleared(true);
            let newClick = [];
            let scenarioData =
              response.data["CustomizedView_LoadPreviousScenario"];
            let gsm1,
              com1,
              pro1,
              loc1,
              bran1 = "";

            let g2 = scenarioData["GSM_val"].split("_");
            const gsmFilter = setStatetoContext(scenarioData["GSM_Values"]);
            const brnadFilter = setStatetoContext(scenarioData["Brand_Values"]);
            const locFilter = setStatetoContext(
              scenarioData["Location_Values"]
            );
            const comFilter = setStatetoContext(scenarioData["CoM_Values"]);
            const proFilter = setStatetoContext(scenarioData["Product_Values"]);

            context.setIsGsmSelectAll(scenarioData["GSM_Sel_All"]);
            context.setIsBrandSelectAll(scenarioData["Brand_Sel_All"]);
            context.setIsLocationSelectAll(scenarioData["Location_Sel_All"]);
            context.setIsCoMSelectAll(scenarioData["CoM_Sel_All"]);
            context.setIsProductSelectAll(scenarioData["Product_Sel_All"]);
            setGsm(scenarioData["GSM_val"]);
            newClick.push(scenarioData["GSM_val"]);
            gsm1 = g2[0];

            if (scenarioData["Children_03"] === "Brand") {
              if (
                scenarioData["Children_03_val"] != "" &&
                scenarioData["Children_03_val"] != null
              ) {
                let g1 = scenarioData["Children_03_val"].split("_");

                //props.setSelectedBrand(scenarioData["Children_03_val"])
                setBran(scenarioData["Children_03_val"]);
                bran1 = g1[0];
                newClick.push(scenarioData["Children_03_val"]);
              }
            } else if (scenarioData["Children_02"] === "Brand") {
              if (
                scenarioData["Children_02_val"] != "" &&
                scenarioData["Children_02_val"] != null
              ) {
                let g1 = scenarioData["Children_02_val"].split("_");
                //context.setPipeline()
                //props.setSelectedBrand(`${g1[0]}-${g1[1]}`)
                setBran(scenarioData["Children_02_val"]);
                bran1 = g1[0];
                newClick.push(scenarioData["Children_02_val"]);
              }
            } else if (scenarioData["Children_01"] === "Brand") {
              if (
                scenarioData["Children_01_val"] !== "" &&
                scenarioData["Children_01_val"] !== null
              ) {
                let g1 = scenarioData["Children_01_val"].split("_");
                //context.setPipeline()
                //props.setSelectedBrand(`${g1[0]}-${g1[1]}`)
                setBran(scenarioData["Children_01_val"]);
                bran1 = g1[0];
                newClick.push(scenarioData["Children_01_val"]);
              }
            }

            if (scenarioData["Children_03"] === "Location") {
              if (
                scenarioData["Children_03_val"] !== "" &&
                scenarioData["Children_03_val"] !== null
              ) {
                let g1 = scenarioData["Children_03_val"].split("_");

                //props.setSelectedLocation(`${g1[0]}-${g1[1]}`)
                setLoc(scenarioData["Children_03_val"]);
                loc1 = g1[0];
                newClick.push(scenarioData["Children_03_val"]);
              }
            } else if (scenarioData["Children_02"] === "Location") {
              if (
                scenarioData["Children_02_val"] !== null &&
                scenarioData["Children_02_val"] !== ""
              ) {
                let g1 = scenarioData["Children_02_val"].split("_");
                //context.setLocation();
                //props.setSelectedLocation(`${g1[0]}-${g1[1]}`)
                setLoc(scenarioData["Children_02_val"]);
                loc1 = g1[0];
                newClick.push(scenarioData["Children_02_val"]);
              }
            } else if (scenarioData["Children_01"] === "Location") {
              if (
                scenarioData["Children_01_val"] !== "" &&
                scenarioData["Children_01_val"] !== null
              ) {
                //context.setLocation();
                let g1 = scenarioData["Children_01_val"].split("_");
                //props.setSelectedLocation(`${g1[0]}-${g1[1]}`)
                setLoc(scenarioData["Children_01_val"]);
                loc1 = g1[0];
                newClick.push(scenarioData["Children_01_val"]);
              }
            }

            if (scenarioData["Children_03"] === "CoM") {
              if (
                scenarioData["Children_03_val"] !== "" &&
                scenarioData["Children_03_val"] !== null
              ) {
                let g1 = scenarioData["Children_03_val"].split("_");

                //props.setSelectedCom(`${g1[0]}-${g1[1]}`)
                setCo(scenarioData["Children_03_val"]);
                com1 = g1[0];
                newClick.push(scenarioData["Children_03_val"]);
              }
            } else if (scenarioData["Children_02"] === "CoM") {
              if (
                scenarioData["Children_02_val"] != "" &&
                scenarioData["Children_02_val"] != null
              ) {
                let g1 = scenarioData["Children_02_val"].split("_");
                //props.setSelectedCom(`${g1[0]}-${g1[1]}`)
                setCo(scenarioData["Children_02_val"]);
                com1 = g1[0];
                newClick.push(scenarioData["Children_02_val"]);
              }
            } else if (scenarioData["Children_01"] === "CoM") {
              if (
                scenarioData["Children_01_val"] !== "" &&
                scenarioData["Children_01_val"] !== null
              ) {
                let g1 = scenarioData["Children_01_val"].split("_");
                //props.setSelectedCom(`${g1[0]}-${g1[1]}`)
                setCo(scenarioData["Children_01_val"]);
                com1 = g1[0];
                newClick.push(scenarioData["Children_01_val"]);
              }
            }

            if (scenarioData["Children_03"] === "Product") {
              if (
                scenarioData["Children_03_val"] !== "" &&
                scenarioData["Children_03_val"] !== null
              ) {
                let g1 = scenarioData["Children_03_val"].split("_");
                //props.setSelectedProduct(`${g1[0]}-${g1[1]}`)
                setPro(scenarioData["Children_03_val"]);
                pro1 = g1[0];
                newClick.push(scenarioData["Children_03_val"]);
              }
            } else if (scenarioData["Children_02"] === "Product") {
              if (
                scenarioData["Children_02_val"] !== "" &&
                scenarioData["Children_02_val"] !== null
              ) {
                //context.setProduct()
                let g1 = scenarioData["Children_02_val"].split("_");
                //props.setSelectedProduct(`${g1[0]}-${g1[1]}`)
                setPro(scenarioData["Children_02_val"]);
                pro1 = g1[0];
                newClick.push(scenarioData["Children_02_val"]);
              }
            } else if (scenarioData["Children_01"] == "Product") {
              if (
                scenarioData["Children_01_val"] !== "" &&
                scenarioData["Children_01_val"] !== null
              ) {
                let g1 = scenarioData["Children_01_val"].split("_");
                setPro(scenarioData["Children_01_val"]);
                pro1 = g1[0];
                newClick.push(scenarioData["Children_01_val"]);
              }
            }
            if (gsmFilter !== null) {
              context.setGsmNetwork(gsmFilter);
            }
            if (brnadFilter !== null) context.setPipeline(brnadFilter);
            if (locFilter !== null) context.setLocation(locFilter);
            if (comFilter !== null) context.setCom(comFilter);
            if (proFilter !== null) context.setProduct(proFilter);

            setUomButton(scenarioData.unit);
            setNewFilterVal(scenarioData.hierarchy_level.split(","));
            props.setMonthYear(scenarioData.snapshot);
            setIsPreviewDisabled(true);
            setIsDeleteDisabled(true);
            setIsSaveDisabled(true);
            previewCustom2(
              scenarioData.table_name,
              scenarioData.CWID,
              scenarioData.hierarchy_level.split(","),
              gsm1,
              bran1,
              loc1,
              pro1,
              com1,
              scenarioData.snapshot
            );
            setClickParent(newClick);
            props.setIsSaveDone(true);
            props.setTableName(scenarioData.table_name);
          } else {
            setLoader(false);
          }
        })
        .catch((error) => {
          console.error("error", error);
          setLoader(false);
          props.setLoader(false);
          showErrorModal(error);
        });
    }
  }

  React.useEffect(() => {
    // debugger
    const cancelToken = axios.CancelToken.source();
    if (!props.isLoadSubmit && props.tableName === "" && isNormDisplay) {
      setPageNumber(1);
      getDefineNormData(1, cancelToken.token);
    } else {
      if (masterNormData !== null) {
        if (masterParentNode !== null) {
          setParentNode(masterParentNode);
        }
        if (masterSubChild1 !== null) {
          setSubChild1(masterSubChild1);
        }
        if (masterSubChild2 !== null) {
          setSubChild2(masterSubChild2);
        }
        if (masterSubChild3 !== null) {
          setSubChild3(masterSubChild3);
        }
        if (masterSubChild4 !== null) {
          setSubChild4(masterSubChild4);
        }
        setNodesEdited([]);
      }
    }
    return () => {
      cancelToken.cancel();
    };
  }, [isNormDisplay]);

  React.useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    let timeout = null;
    if (isFilterChange) {
      setPageNumber(1);

      if (props.tableName == "" || props.tableName == undefined)
        timeout = setTimeout(getDefineNormData(1, cancelToken.token), 3000);
      else {
        load_Preview(1, props.tableName, cancelToken.token);
      }
    }
    return () => {
      cancelToken.cancel();
      clearTimeout();
      timeout = null;
    };
  }, [isFilterChange]);

  React.useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (isPreviewClicked) {
      if (context.gsmNetwork.length > 0) {
        previewCustom(cancelToken.token);
        setIsPreviewClicked(false);
      } else {
        setIsPreviewClicked(false);
        showErrorModal(
          "You need to choose gsm network in the top filters to preview customization scenario"
        );
      }
    }
    return () => {
      cancelToken.cancel();
    };
  }, [isPreviewClicked]);

  const clickParentNode = (e) => {
    debugger;
    setPageNumber(1);
    const id = e.target.id;
    const code = id.split("_")[0];

    setGsm(code);
    if (clickParent.indexOf(id) === -1) {
      setParentNode([]);
      setSubChild1([]);
      setSubChild2([]);
      setSubChild3([]);
      setSubChild4([]);
      setClickParent(
        clickParent.length == 0 ? [e.target.id] : [...clickParent, e.target.id]
      );
      setIsClicked(id);
    } else {
      if (subChild1 !== null && subChild1.length > 0) {
        var constData = subChild1.map((item) => {
          item.isEnable = item.isEnable ? false : true;
          return item;
        });
        setSubChild1(constData);
      }
    }
  };

  const handleAddNodesEdited = (item, treeId) => {
    if (nodesEdited.length > 0) {
      let nodes = [...nodesEdited];
      let newNodes = nodes.map((child) =>
        child.TreeNodeId === item.TreeNodeId ? { ...item } : child
      );
      if (!nodes.find((child) => child.TreeNodeId === item.TreeNodeId)) {
        newNodes.push(item);
      }
      setNodesEdited(newNodes);
    } else {
      setNodesEdited([item]);
    }
    setIsPreviewDisabled(false);
  };
  const LoadParent = (data1, normUom) => {
    const handleSetItemChanges = (updatedItem, TreeNodeId) => {
      setParentNode(
        parentNode.map((child) =>
          child.TreeNodeId === updatedItem.TreeNodeId
            ? { ...updatedItem }
            : child
        )
      );
    };
    return data1.map((item) => {
      if (item.TreeNodeId !== "" && item.TreeNodeId !== "null") {
        retainChangedValueinTree(nodesEdited, item);
        return (
          <>
            <li style={liStyle()} key={item.TreeNodeId}>
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.treeNodeId}
                  </span>
                }
                open={
                  context.showTooltips && context.buttonHoverStates === item
                }
                placement="bottom"
              >
                <span
                  style={treeSpanStyle()}
                  id={item.TreeNodeId}
                  onClick={(e) => clickParentNode(e)}
                  className="treeParentSpanStyle"
                  onMouseEnter={() => context.setButtonHoverStates(item)}
                  onMouseLeave={() => context.setButtonHoverStates("")}
                >
                  {item.TreeNodeId}
                </span>
              </Tooltip>
              <InnerItem
                treeId={item.TreeNodeId}
                itemD={item}
                key={item.TreeNodeId}
                handleSetItemChanges={handleSetItemChanges}
                handleAddNodesEdited={handleAddNodesEdited}
                normUom={normUom}
              />
            </li>

            {subChild1 != undefined &&
            subChild1.length > 0 &&
            clickParent.indexOf(item.TreeNodeId.trim()) != -1 ? (
              <ul
                id="subChild1"
                key={item.TreeNodeId}
                style={ulStyle()}
                className={
                  selectedFilters.length == 2 ? "notExpandable" : "subChild1"
                }
              >
                {LoadSubChild(subChild1, normUom)}
              </ul>
            ) : (
              ""
            )}
          </>
        );
      }
    });
  };

  const getSubChild = (e, index) => {
    //debugger;
    const id = e.target.id;
    setPageNumber(1);

    if (!props.isLoadSubmit) resetPropsBasedOnFilter(id, index);

    if (clickParent.indexOf(e.target.id) == -1) {
      setParentNode([]);
      setSubChild1([]);
      setSubChild2([]);
      setSubChild3([]);
      setSubChild4([]);
      setClickParent(
        clickParent.length == 0 ? [e.target.id] : [...clickParent, e.target.id]
      );

      setIsClicked(id);
    } else {
      if (subChild2 != null && subChild2.length > 0 && index == 1) {
        var constData = subChild2.map((item) => {
          item.isEnable = item.isEnable ? false : true;

          return item;
        });
        setSubChild2(constData);
      }
      if (subChild3 != null && subChild3.length > 0 && index == 2) {
        var constData = subChild3.map((item) => {
          item.isEnable = item.isEnable ? false : true;
          return item;
        });
        setSubChild3(constData);
      }
      if (subChild4 != null && subChild4.length > 0 && index == 3) {
        var constData = subChild4.map((item) => {
          item.isEnable = item.isEnable ? false : true;
          return item;
        });
        setSubChild4(constData);
      }
    }
  };

  const LoadSubChild = (data1, normUom) => {
    const handleSetItemChanges = (updatedItem, TreeNodeId) => {
      setSubChild1(
        subChild1.map((child) =>
          child.TreeNodeId === updatedItem.TreeNodeId
            ? { ...updatedItem }
            : child
        )
      );
    };

    return data1.map((item) => {
      retainChangedValueinTree(nodesEdited, item);
      if (item.TreeNodeId != "" && item.TreeNodeId != "null" && item.isEnable) {
        return (
          <>
            <li style={liStyle()} key={item.TreeNodeId}>
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.treeNodeId}
                  </span>
                }
                open={
                  context.showTooltips && context.buttonHoverStates === item
                }
                placement="bottom"
              >
                <span
                  style={treeSpanStyle()}
                  id={item.TreeNodeId}
                  onClick={(e) => getSubChild(e, 1)}
                  className="treeChildSpanStyle"
                  onMouseEnter={() => context.setButtonHoverStates(item)}
                  onMouseLeave={() => context.setButtonHoverStates("")}
                >
                  {item.TreeNodeId}
                </span>
              </Tooltip>
              <InnerItem
                treeId={item.TreeNodeId}
                itemD={item}
                key={item.TreeNodeId}
                handleSetItemChanges={handleSetItemChanges}
                handleAddNodesEdited={handleAddNodesEdited}
                normUom={normUom}
              />
            </li>
            {subChild2 != undefined &&
            subChild2.length > 0 &&
            clickParent.indexOf(item.TreeNodeId.trim()) != -1 ? (
              <ul
                id="subChild2"
                style={ulStyle()}
                key={item.TreeNodeId}
                className={
                  selectedFilters.length == 2 ? "notExpandable" : "subChild1"
                }
              >
                {LoadSubChild2(subChild2, normUom)}
              </ul>
            ) : (
              ""
            )}
          </>
        );
      }
    });
  };

  const LoadSubChild2 = (data1, normUom) => {
    const handleSetItemChanges = (updatedItem, TreeNodeId) => {
      setSubChild2(
        subChild2.map((child) =>
          child.TreeNodeId === updatedItem.TreeNodeId
            ? { ...updatedItem }
            : child
        )
      );
    };
    return data1.map((item) => {
      retainChangedValueinTree(nodesEdited, item);
      if (item.TreeNodeId != "" && item.TreeNodeId != "null" && item.isEnable) {
        // if (item.Level == "0") {
        return (
          <>
            <li style={liStyle()} key={item.TreeNodeId}>
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.treeNodeId}
                  </span>
                }
                open={
                  context.showTooltips && context.buttonHoverStates === item
                }
                placement="bottom"
              >
                <span
                  style={treeSpanStyle()}
                  id={item.TreeNodeId}
                  onClick={(e) => getSubChild(e, 2)}
                  className="treeChild2SpanStyle"
                  onMouseEnter={() => context.setButtonHoverStates(item)}
                  onMouseLeave={() => context.setButtonHoverStates("")}
                >
                  {item.TreeNodeId}
                </span>
              </Tooltip>
              <InnerItem
                treeId={item.TreeNodeId}
                itemD={item}
                key={item.TreeNodeId}
                handleSetItemChanges={handleSetItemChanges}
                handleAddNodesEdited={handleAddNodesEdited}
                normUom={normUom}
              />
            </li>
            {subChild3 != undefined &&
            subChild3.length > 0 &&
            clickParent.indexOf(item.TreeNodeId.trim()) != -1 ? (
              <ul
                id="subChild3"
                style={ulStyle()}
                key={item.TreeNodeId}
                className={
                  selectedFilters.length == 2 ? "notExpandable" : "subChild2"
                }
              >
                {LoadSubChild3(subChild3, normUom)}
              </ul>
            ) : (
              ""
            )}
          </>
        );
        //}
      }
    });
  };

  const LoadSubChild3 = (data1, normUom) => {
    const handleSetItemChanges = (updatedItem, TreeNodeId) => {
      setSubChild3(
        subChild3.map((child) =>
          child.TreeNodeId === updatedItem.TreeNodeId
            ? { ...updatedItem }
            : child
        )
      );
    };

    return data1.map((item) => {
      retainChangedValueinTree(nodesEdited, item);
      if (item.TreeNodeId != "" && item.TreeNodeId != "null" && item.isEnable) {
        // if (item.Level == "0") {
        return (
          <>
            <li style={liStyle()} key={item.TreeNodeId}>
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.treeNodeId}
                  </span>
                }
                open={
                  context.showTooltips &&
                  context.buttonHoverStates === item.TreeNodeId
                }
                placement="bottom"
              >
                <span
                  style={treeSpanStyle()}
                  id={item.TreeNodeId}
                  onClick={(e) => getSubChild(e, 3)}
                  className="treeChild3SpanStyle"
                  onMouseEnter={() =>
                    context.setButtonHoverStates(item.TreeNodeId)
                  }
                  onMouseLeave={() => context.setButtonHoverStates("")}
                >
                  {item.TreeNodeId}
                </span>
              </Tooltip>
              <InnerItem
                treeId={item.TreeNodeId}
                itemD={item}
                key={item.TreeNodeId}
                handleSetItemChanges={handleSetItemChanges}
                handleAddNodesEdited={handleAddNodesEdited}
                normUom={normUom}
              />
            </li>
            {subChild4 != undefined &&
            subChild4.length > 0 &&
            clickParent.indexOf(item.TreeNodeId.trim()) != -1 ? (
              <ul
                id="subChild4"
                key={item.TreeNodeId}
                style={ulStyle()}
                className={
                  selectedFilters.length == 3 ? "notExpandable" : "subChild3"
                }
              >
                {LoadSubChild4(subChild4, normUom)}
              </ul>
            ) : (
              ""
            )}
          </>
        );
        //}
      }
    });
  };

  const LoadSubChild4 = (data1, normUom) => {
    const handleSetItemChanges = (updatedItem, TreeNodeId) => {
      setSubChild4(
        subChild4.map((child) =>
          child.TreeNodeId === updatedItem.TreeNodeId
            ? { ...updatedItem }
            : child
        )
      );
    };
    return data1.map((item) => {
      retainChangedValueinTree(nodesEdited, item);

      if (item.TreeNodeId != "" && item.TreeNodeId != "null" && item.isEnable) {
        // if (item.Level == "0") {
        return (
          <>
            <li style={liStyle()} key={item.TreeNodeId}>
              <span
                style={treeSpanStyle()}
                id={item.TreeNodeId}
                className="treeChild4SpanStyle"
              >
                {item.TreeNodeId}
              </span>
              <InnerItem
                treeId={item.TreeNodeId}
                itemD={item}
                handleSetItemChanges={handleSetItemChanges}
                handleAddNodesEdited={handleAddNodesEdited}
                normUom={normUom}
              />
            </li>
          </>
        );
        //}
      }
    });
  };

  React.useEffect(() => {
    if (isSubmitClicked) {
      const secnarioName1 = document.getElementById("secnarioName").value;
      if (secnarioName1 == "" || secnarioName1 == null) {
        showErrorModal("Scenario Name can not be blank");
      } else if (checkDuplicacy(secnarioName1)) {
        showSaveModal(`Scenario with this name “${secnarioName1}” already exists.
            Would you like to overwrite this scenario?
            
            Your previous version will be deleted`);
      } else {
        saveCustom();
      }
      setIsSubmitClicked(false);
    }
  }, [isSubmitClicked]);

  React.useEffect(() => {
    if (parentNode != null) {
      LoadParent(parentNode, normUom);
    }
  }, [parentNode, subChild1, subChild2, subChild3, subChild4]);
  /*load Tree data for previously created Scenario */
  const previewCustom2 = (
    tableName,
    cwid,
    selectedFilters,
    gsm,
    bran,
    loc,
    pro,
    co,
    monthYear
  ) => {
    let normData_Query = "";
    const page1 = pageNumber;
    const gms = gsm;
    let tableName1 = tableName;

    setNodeAfterPreview(nodesEdited);
    setNodesEdited([]);

    normData_Query = getNewPreviewCustomQuery(
      selectedFilters,
      gms,
      bran,
      co,
      loc,
      pro,
      // dataRef,
      page1,
      monthYear,
      cwid,
      tableName1,
      sortButton,
      FilterSub
    );

    if (normData_Query) {
      setLoader(true);
      callAPI(normData_Query, "")
        .then((response) => {
          if (
            response.data !== undefined &&
            response.data !== undefined &&
            response.data["LOAD_Preview"] !== undefined &&
            response.data["LOAD_Preview"] !== null
          ) {
            //props.setTableName(tableName1);
            setHasMoreItems(false);
            setMasterNormData(response.data["LOAD_Preview"]);

            let NORMCustomizedView_Parent = [];
            let NORMCustomizedView_Children_01 = [];
            let NORMCustomizedView_Children_02 = [];
            let NORMCustomizedView_Children_03 = [];
            let NORMCustomizedView_Children_04 = [];

            if (response.data["LOAD_Preview"]["Parent"]) {
              NORMCustomizedView_Parent = addCustomVariable(
                response.data["LOAD_Preview"]["Parent"],
                "A"
              );
              setParentNode(NORMCustomizedView_Parent);
              setMasterParentNode(NORMCustomizedView_Parent);
            }

            if (response.data["LOAD_Preview"]["Children_01"]) {
              NORMCustomizedView_Children_01 = addCustomVariable(
                response.data["LOAD_Preview"]["Children_01"],
                "B"
              );

              setSubChild1(NORMCustomizedView_Children_01);
              setMasterSubChild1(NORMCustomizedView_Children_01);
            }
            if (response.data["LOAD_Preview"]["Children_02"]) {
              NORMCustomizedView_Children_02 = addCustomVariable(
                response.data["LOAD_Preview"]["Children_02"],
                "C"
              );
              setSubChild2(NORMCustomizedView_Children_02);
              setMasterSubChild2(NORMCustomizedView_Children_02);
            }
            if (response.data["LOAD_Preview"]["Children_03"]) {
              NORMCustomizedView_Children_03 = addCustomVariable(
                response.data["LOAD_Preview"]["Children_03"],
                "D"
              );
              setSubChild3(NORMCustomizedView_Children_03);
              setMasterSubChild3(NORMCustomizedView_Children_03);
            }
            if (response.data["LOAD_Preview"]["Children_04"]) {
              NORMCustomizedView_Children_04 = addCustomVariable(
                response.data["LOAD_Preview"]["Children_04"],
                "E"
              );
              setSubChild4(NORMCustomizedView_Children_04);
              setMasterSubChild4(NORMCustomizedView_Children_04);
            }

            setIsPreviewDisabled(true);
            setIsSaveDisabled(false);
            setIsDeleteDisabled(false);
            props.setIsLoadData(true);

            setLoader(false);
          } else {
            showErrorModal(response);
            setLoader(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoader(false);
          showErrorModal(error);
        });
    }
  };

  /*load the Tree data after Preview Scenario */
  const previewScenario = (tableName) => {
    let normData_Query = "";
    const page1 = pageNumber;
    const gms = gsm;
    let tableName1 = tableName;
    let EditedNode = nodesEdited;
    setNodeAfterPreview(nodesEdited);
    setNodesEdited([]);
    normData_Query = getNewPreviewCustomQuery(
      selectedFilters,
      gms,
      bran,
      co,
      loc,
      pro,
      page1,
      props.monthYear,
      cwid,
      tableName1,
      sortButton,
      FilterSub
    );

    if (normData_Query) {
      callAPI(normData_Query, "")
        .then((response) => {
          if (
            response.data !== undefined &&
            response.data["LOAD_Preview"] !== null
          ) {
            let NORMCustomizedView_Parent = [];
            let NORMCustomizedView_Children_01 = [];
            let NORMCustomizedView_Children_02 = [];
            let NORMCustomizedView_Children_03 = [];
            let NORMCustomizedView_Children_04 = [];

            if (response.data["LOAD_Preview"]["Parent"]) {
              NORMCustomizedView_Parent = addCustomVariable(
                response.data["LOAD_Preview"]["Parent"],
                "A"
              );
              setParentNode(NORMCustomizedView_Parent);
              setMasterParentNode(NORMCustomizedView_Parent);
            }
            if (response.data["LOAD_Preview"]["Children_01"]) {
              NORMCustomizedView_Children_01 = addCustomVariable(
                response.data["LOAD_Preview"]["Children_01"],
                "B"
              );
              setSubChild1(NORMCustomizedView_Children_01);
              setMasterSubChild1(NORMCustomizedView_Children_01);
            }
            if (response.data["LOAD_Preview"]["Children_02"]) {
              NORMCustomizedView_Children_02 = addCustomVariable(
                response.data["LOAD_Preview"]["Children_02"],
                "C"
              );
              setSubChild2(NORMCustomizedView_Children_02);
              setMasterSubChild2(NORMCustomizedView_Children_02);
            }
            if (response.data["LOAD_Preview"]["Children_03"]) {
              NORMCustomizedView_Children_03 = addCustomVariable(
                response.data["LOAD_Preview"]["Children_03"],
                "D"
              );
              setSubChild3(NORMCustomizedView_Children_03);
              setMasterSubChild3(NORMCustomizedView_Children_03);
            }
            if (response.data["LOAD_Preview"]["Children_04"]) {
              NORMCustomizedView_Children_04 = addCustomVariable(
                response.data["LOAD_Preview"]["Children_04"],
                "E"
              );
              setSubChild4(NORMCustomizedView_Children_04);
              setMasterSubChild4(NORMCustomizedView_Children_04);
            }
            setIsPreviewDisabled(true);
            setIsSaveDisabled(false);
            setIsDeleteDisabled(true);
            props.setIsLoadData(true);
            setIsClicked("");
            setLoader(false);
          } else {
            showErrorModal("something went wrong");
            changeLoaderState();
          }
        })
        .catch((error) => {
          console.error(error);
          showErrorModal(error.data.errors[0].message);
          changeLoaderState();
        });
    }
  };

  const getTopFilterBasedonPreviewData = (editedNodes) => {
    if (editedNodes.length > 0) {
      editedNodes.map((item) => {
        //debugger
        if (item.CustomLevel == "B") {
          setTopFilterValue(1, item);
        } else if (item.CustomLevel == "C") {
          setTopFilterValue(2, item);
        } else if (item.CustomLevel == "D") {
          setTopFilterValue(3, item);
        } else if (item.CustomLevel == "E") {
          setTopFilterValue(4, item);
        }
      });
    }
  };

  const setTopFilterValue = (index, itemVal) => {
    if (selectedFilters.length > 1) {
      const nodeVal = itemVal.TreeNodeId.split("_");
      if (selectedFilters[index] == "Pipeline") {
        context.setPipeline([
          ...context.pipeline,
          { code: nodeVal[0], name: `${nodeVal[0]}-${nodeVal[1]}` },
        ]);
      } else if (selectedFilters[index] == "CoM") {
        context.setCom([
          ...context.com,
          { code: nodeVal[0], name: `${nodeVal[0]}-${nodeVal[1]}` },
        ]);
      } else if (selectedFilters[index] == "Location") {
        context.setLocation([
          ...context.location,
          { code: nodeVal[0], name: `${nodeVal[0]}-${nodeVal[1]}` },
        ]);
      } else if (selectedFilters[index] == "Material") {
        context.setProduct([
          ...context.product,
          { code: nodeVal[0], name: `${nodeVal[0]}-${nodeVal[1]}` },
        ]);
      }
    }
  };

  const previewCustom = (token) => {
    let dataForPreview = [
      ...parentNode,
      ...subChild1,
      ...subChild2,
      ...subChild3,
      ...subChild4,
    ];
    const gms = gsm;
    const normData_Query = getPreviewNormsQuery(
      selectedFilters,
      gms,
      false,
      props.monthYear,
      dataForPreview,
      normUom,
      nodesEdited,
      cwid,
      uomButton,
      FilterSub

    );

    if (normData_Query) {
      setLoader(true);
      callAPI(normData_Query, "")
        .then((response) => {
          if (
            response.data !== undefined &&
            response.data["Preview"] !== undefined &&
            response.data["Preview"]["table_name"] !== ""
          ) {
            const tableName = response.data["Preview"]["table_name"];
            props.setTableName(tableName);
            let id = setInterval(function () {
              checkIfScenarioExist(tableName);
            }, 2000);
            const checkIfScenarioExist = (tableName) => {
              callAPI(
                `mutation MyMutation {CheckTable(table_name: "${tableName}")}`,
                ""
              )
                .then((response) => {
                  if (
                    response.data !== undefined &&
                    response.data["CheckTable"] !== undefined
                  ) {
                    if (response.data["CheckTable"]) {
                      clearInterval(id);
                      previewScenario(tableName);
                    }
                  } else {
                    showErrorModal(response.data);
                    setLoader(false);
                  }
                })
                .catch((error) => {
                  console.error(error);
                  showErrorModal(error);
                  changeLoaderState();
                });
            };
          } else {
            showErrorModal(response.data["Preview"]["validation_status"]);
            changeLoaderState();
          }
        })
        .catch((error) => {
          console.error(error);
          showErrorModal(error.data.errors[0].message);
          changeLoaderState();
        });
    }
  };

  const saveCustom = () => {
    if (
      role &&
      ((userGsm === gsm && role.toLowerCase().includes("e2e planner")) ||
        role.toLowerCase() === "admin" ||
        role.toLowerCase() === "gsm")
    ) {
      const secnarioName1 = document.getElementById("secnarioName").value;
      const dataRef = treeRef.current.children[1];
      let dataForPreview = [
        ...parentNode,
        ...subChild1,
        ...subChild2,
        ...subChild3,
        ...subChild4,
      ];
      if (secnarioName1 != null && secnarioName1 != "") {
        props.setIsSaveDone(false);
        if (dataRef != null) {
          const gms =
            gsm === ""
              ? dataRef.getElementsByTagName("li")[0].children[0].id
              : gsm;
          const { gsmCode, locCode, comCode, proCode, pipCode } =
            context.getFilterValues();
          const topFilterquery = `Brand_Sel_All:${
            context.isBrandSelectAll
          }, Brand_Values: "${pipCode}",
				 CoM_Sel_All: ${context.isCoMSelectAll}, CoM_Values: "${comCode}",
				 GSM_Sel_All:${context.isGsmSelectAll},GSM_Values: "${gsmCode}",
				 Location_Sel_All: ${
           context.isLocationSelectAll
         }, Location_Values: "${locCode}",
				  Product_Sel_All: ${
            context.isProductSelectAll
          }, Product_Values: "${proCode}",sort_by:"${sortButton.toLowerCase()}"`;
          const normData_Query = getSaveNormsQuery(
            selectedFilters,
            gms,
            secnarioName1,
            false,
            props.monthYear,
            dataForPreview,
            normUom,
            nodeAfterPreview,
            currentUser,
            cwid,
            props.tableName,
            uomButton,
            topFilterquery
          );
          if (normData_Query) {
            changeLoaderState();
            callAPI(normData_Query, "")
              .then((response) => {
                if (
                  response.data !== undefined &&
                  response.data["CustomizedView_SAVE"] !== undefined
                ) {
                  props.getScenario("");
                  setIsSaveDisabled(true);
                  setIsPreviewDisabled(true);
                  setIsDeleteDisabled(false);
                  showErrorModal("Saved Successfully");
                  //setClickParent([]);
                  props.setTableName("");
                  props.setIsSaveDone(true);
                  props.setLoader(false);
                  changeLoaderState(false);
                } else {
                  showErrorModal(response.data.errors[0].message);
                  changeLoaderState(false);
                }
              })
              .catch((error) => {
                showErrorModal(error.data.errors[0].message);
                changeLoaderState(false);
              });
          }
        }
      } else {
        showErrorModal("Scenario Name can not be blank");
      }
    } else {
      showErrorModal(
        `You are not allowed to Save and Edit Scenario under selected GSM. Please select the assigned GSM to save Scenario.`
      );
    }
  };

  function checkForMore(e) {
    setHasMoreItems(true);
  }
  const onUnitChange = (e) => {
    if (e.target.value === 0) {
      e.target.value = 0;
      setUomButton("Value");
      setNormUom(0);
    } else if (e.target.value === 1) {
      e.target.value = 1;
      setUomButton("Qty");
      setNormUom(1);
    } else if (e.target.value === 2) {
      e.target.value = 2;
      setUomButton("DoS");
      setNormUom(2);
    }
  };

  const handleGoBack = () => {
    if (clickParent.length > 0) {
      setParentNode([]);
      setSubChild1([]);
      setSubChild2([]);
      setSubChild3([]);
      setSubChild4([]);

      let clickParentArr = [...clickParent];
      let clickParentLastIndex = clickParent.length - 1;
      if (clickParentArr.length > 0) {
        clickParentArr.pop();

        setClickParent([...clickParentArr]);
        if (clickParentArr.length > 0) {
          resetPropsBasedOnFilter("", clickParentLastIndex);
          setIsClicked(clickParentLastIndex);
        } else {
          if (
            !props.isLoadSubmit &&
            (props.tableName === "" || props.tableName === undefined)
          ) {
            setGsm("");
            setPageNumber(1);
            getGoBackDefineNormData(1);
          } else {
            setGsm("");
            setPageNumber(1);
            load_Preview(1, props.tableName);
          }
        }
      }
    }
  };

  const getGoBackDefineNormData = (page) => {
    props.setIsLoadSubmit(false);
    let gms = "";
    setLoader(true);
    const page1 = page != null ? page : pageNumber;
    const normData_Query = getNormsQuery(
      selectedFilters,
      gms,
      bran,
      co,
      loc,
      pro,
      page1,
      props.monthYear,
      cwid,
      sortButton,
      FilterSub
    );
    callAPI(normData_Query, "")
      .then((response) => {
        if (
          response.data !== undefined &&
          response.data !== undefined &&
          response.data["NORMCustomizedView"] !== undefined &&
          response.data["NORMCustomizedView"] !== null
        ) {
          let data = [];
          data = response.data["NORMCustomizedView"];
          setHasMoreItems(false);
          setMasterNormData(data);
          if (
            data["Parent"] != null &&
            (data["Children_01"] == null || data["Children_01"].length == 0)
          ) {
            const data1 = addCustomVariable(data["Parent"], "A");
            setParentNode([...data1]);
            setSubChild1([]);
            setSubChild2([]);
            setSubChild3([]);
            setSubChild4([]);
            setMasterParentNode([...data1]);
            setMasterSubChild1([]);
            setMasterSubChild2([]);
            setMasterSubChild3([]);
            setMasterSubChild4([]);
            setIsClicked("");
          }
          setPageNumber(pageNumber);
          setIsFilterChange(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        setIsFilterChange(false);
        console.error("error", error);
      });
  };

  let stickyHeaderClassName = "ml-2 ";
  let stickyButtonClassName = "customizer-buttons ";
  if (showSave || showError || resetModalShow || deleteModalShow) {
    stickyHeaderClassName += "  ";
    stickyButtonClassName += "  ";
  } else {
    stickyHeaderClassName += " sticky-table-header ";
    stickyButtonClassName += " sticky-button-header ";
  }

  return (
    <div style={{ position: "relative" }}>
      <div
        className={`customNormContainer ${isNormDisplay ? "divUp" : "divDown"}`}
      >
        <Tooltip
          title={
            <span style={{ fontSize: "16px" }}>
              {tooltipsContent.customNormContainer}
            </span>
          }
          open={
            context.showTooltips &&
            context.buttonHoverStates === "collapsibleButton cursor"
          }
        >
          <div
            className="collapsibleButton cursor"
            onClick={handleExpandButton}
            style={{ visibility: isNormDisplay ? "hidden" : "visible" }}
            onMouseEnter={() =>
              context.setButtonHoverStates("collapsibleButton cursor")
            }
            onMouseLeave={() => context.setButtonHoverStates("")}
          >
            <img
              src="../../AppAssets/defineNorm_blue.png"
              alt="up"
              className="defineNormArrow_blue"
            />
            <span className="defineText" style={{ color: "#00BCFF" }}>
              Define NORM+ @customized
            </span>
          </div>
        </Tooltip>
        <div
          className="customNorm_innerContainer"
          style={{ display: isNormDisplay ? "block" : "none" }}
        >
          <div className="buttonBar">
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
              className="cursor"
              onClick={handleCollapsibleButton}
            >
              <img
                src="../../AppAssets/defineNorm_black.png"
                alt="up"
                className="defineNormArrow_black"
              />
              <span className="defineText" style={{ color: "#3F3F3F" }}>
                Define NORM+ @customized
              </span>
            </div>

            <div style={{ display: "flex", marginLeft: "4rem" }}>
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.Preview}
                  </span>
                }
                open={
                  context.showTooltips &&
                  context.buttonHoverStates === "button preview"
                }
              >
                <div>
                  <Button
                    className="button preview"
                    disabled={isPreviewDisabled}
                    style={{
                      color: isPreviewDisabled
                        ? "#888888 !important"
                        : "#000000",
                    }}
                    onClick={(e) => setIsPreviewClicked(true)}
                    onMouseEnter={() =>
                      context.setButtonHoverStates("button preview")
                    }
                    onMouseLeave={() => context.setButtonHoverStates("")}
                  >
                    <img
                      src="../../AppAssets/preview.png"
                      alt="preview"
                      className="previewIcon"
                    />
                    Preview
                  </Button>
                </div>
              </Tooltip>

              {role &&
              (role.toLowerCase().includes("e2e planner") ||
                role.toLowerCase() == "admin" ||
                role.toLowerCase() == "gsm") ? (
                <>
                  <Tooltip
                    title={
                      <span style={{ fontSize: "16px" }}>
                        {tooltipsContent.Save}
                      </span>
                    }
                    open={
                      context.showTooltips &&
                      context.buttonHoverStates === "button save"
                    }
                  >
                    <Button
                      className="button save"
                      disabled={isSaveDisabled}
                      style={{
                        marginLeft: "2rem",
                        backgroundColor: isSaveDisabled ? "#555555" : "#000000",
                      }}
                      onClick={(e) => setIsSubmitClicked(true)}
                      onMouseEnter={() =>
                        context.setButtonHoverStates("button save")
                      }
                      onMouseLeave={() => context.setButtonHoverStates("")}
                    >
                      <img
                        src="../../AppAssets/save.png"
                        alt="save"
                        className="saveIcon"
                      />
                      Save
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={
                      <span style={{ fontSize: "16px" }}>
                        {tooltipsContent.Delete}
                      </span>
                    }
                    open={
                      context.showTooltips &&
                      context.buttonHoverStates === "button delete"
                    }
                  >
                    <Button
                      className="button delete"
                      disabled={isDeleteDisabled}
                      style={{
                        color: isDeleteDisabled
                          ? "#888888 !important"
                          : "#000000",
                        marginLeft: "1rem",
                      }}
                      onClick={(e) => props.IsDeleted(true)}
                      onMouseEnter={() =>
                        context.setButtonHoverStates("button delete")
                      }
                      onMouseLeave={() => context.setButtonHoverStates("")}
                    >
                      <img
                        src="../../AppAssets/delete.svg"
                        alt="delete"
                        className="previewIcon"
                      />
                      Delete
                    </Button>
                  </Tooltip>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="customNorm_inner">
            <div className="draggbleFilterContainer">
              <div className="draggbleZone">
                <DraggbleFilter
                  filterValues={filterValues}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  secondList={secondList}
                  setSecondList={setSecondList}
                  setIsFilterChange={setIsFilterChange}
                  setParentNode={setParentNode}
                  setSubChild1={setSubChild1}
                  setSubChild2={setSubChild2}
                  setSubChild3={setSubChild3}
                  setSubChild4={setSubChild4}
                  setPageNumber={setPageNumber}
                />
              </div>
              <div className="dragbar_ButtonArea">
                <div
                  className="title_5 button resetButton"
                  onClick={(e) => setResetModalShow(true)}
                >
                  Reset
                </div>
              </div>
              <ModalPopup
                show={resetModalShow}
                message="Are you sure you want to Reset data?"
                eventName={updateResetButtonState}
                btntext1="Cancel"
                btntext2="Reset"
                setShow={setResetModalShow}
              />
            </div>
            <div className="customNorm_tableContainer">
              <div className="customNorm_tableInnerContainer">
                {loader ? (
                  <Loader />
                ) : isNormDisplay && !isFilterChange ? (
                  <>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      className={stickyButtonClassName}
                      // className="customizer-buttons sticky-button-header"
                    >
                      {clickParent.length > 0 && (
                        <Box className="customizer-button">
                          <Tooltip
                            title={
                              <span style={{ fontSize: "16px" }}>
                                {tooltipsContent.Back}
                              </span>
                            }
                            open={
                              context.showTooltips &&
                              context.buttonHoverStates === "back-button"
                            }
                          >
                            <Button
                              id="back-button"
                              // ref={buttonRef}
                              className="button"
                              onClick={(e) => handleGoBack(e)}
                              // style={{ width: "14rem" }}
                              // value={normUom}
                              onMouseEnter={() =>
                                context.setButtonHoverStates("back-button")
                              }
                              onMouseLeave={() =>
                                context.setButtonHoverStates("")
                              }
                            >
                              Go back
                            </Button>
                          </Tooltip>
                        </Box>
                      )}
                      <Tooltip
                        title={
                          <span style={{ fontSize: "16px" }}>
                            {tooltipsContent.checkForMore}
                          </span>
                        }
                        open={
                          context.showTooltips &&
                          context.buttonHoverStates === "checkForMore"
                        }
                      >
                        <Button
                          onClick={(e) => checkForMore(e)}
                          className="load"
                          style={{ width: "14rem" }}
                          onMouseEnter={() =>
                            context.setButtonHoverStates("checkForMore")
                          }
                          onMouseLeave={() => context.setButtonHoverStates("")}
                        >
                          Load More
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          <span style={{ fontSize: "16px" }}>
                            {tooltipsContent.leftBorder}
                          </span>
                        }
                        open={
                          context.showTooltips &&
                          context.buttonHoverStates === "leftBorder"
                        }
                      >
                        <div
                          style={divStyle()}
                          className="leftBorder"
                          onMouseEnter={() =>
                            context.setButtonHoverStates("leftBorder")
                          }
                          onMouseLeave={() => context.setButtonHoverStates("")}
                        >
                          <span>Indicator</span>
                          <ul
                            id={`unit`}
                            value={normUom}
                            style={{
                              height: "7rem",
                              width: "20rem",
                              fontSize: "1.4rem",
                              listStyle: "none",
                              border: "1px solid grey",
                              padding: ".6rem",
                              marginLeft: "2.5rem",
                            }}
                          >
                            <li
                              value={0}
                              style={{
                                padding: ".2rem .6rem",
                                background:
                                  normUom === 0
                                    ? "rgb(200, 222, 239)"
                                    : "#ffffff",
                              }}
                              onClick={(e) => onUnitChange(e)}
                            >
                              VALUE (€)
                            </li>
                            <li
                              value={1}
                              onClick={(e) => onUnitChange(e)}
                              style={{
                                padding: ".2rem .6rem",
                                background:
                                  normUom === 1
                                    ? "rgb(200, 222, 239)"
                                    : "#ffffff",
                              }}
                            >
                              QUANTITY (BUOM)
                            </li>
                            <li
                              value={2}
                              onClick={(e) => onUnitChange(e)}
                              style={{
                                padding: ".2rem .6rem",
                                background:
                                  normUom === 2
                                    ? "rgb(200, 222, 239)"
                                    : "#ffffff",
                              }}
                            >
                              COVERAGE (days)
                            </li>
                          </ul>
                        </div>
                      </Tooltip>

                      <Tooltip
                        title={
                          <span style={{ fontSize: "16px" }}>
                            {tooltipsContent.leftBorder}
                          </span>
                        }
                        open={
                          context.showTooltips &&
                          context.buttonHoverStates === "SortBy"
                        }
                      >
                        <div
                          style={divStyle()}
                          className="leftBorder"
                          onMouseEnter={() =>
                            context.setButtonHoverStates("SortBy")
                          }
                          onMouseLeave={() => context.setButtonHoverStates("")}
                        >
                          <span>SortBy</span>
                          <ul
                            id={`sortBy`}
                            value={sortButton}
                            style={{
                              height: "7rem",
                              width: "20rem",
                              fontSize: "1.4rem",
                              listStyle: "none",
                              border: "1px solid grey",
                              padding: ".6rem",
                              marginLeft: "2.5rem",
                            }}
                          >
                            <li
                              value={"Value"}
                              style={{
                                padding: ".2rem .6rem",
                                background:
                                  sortButton === "Value"
                                    ? "rgb(200, 222, 239)"
                                    : "#ffffff",
                              }}
                              onClick={() => setSortButton("Value")}
                            >
                              VALUE (€)
                            </li>
                            <li
                              value={"Qty"}
                              onClick={() => setSortButton("Qty")}
                              style={{
                                padding: ".2rem .6rem",
                                background:
                                  sortButton === "Qty"
                                    ? "rgb(200, 222, 239)"
                                    : "#ffffff",
                              }}
                            >
                              QUANTITY (BUOM)
                            </li>
                            <li
                              value={"DoS"}
                              onClick={() => setSortButton("DoS")}
                              style={{
                                padding: ".2rem .6rem",
                                background:
                                  sortButton === "DoS"
                                    ? "rgb(200, 222, 239)"
                                    : "#ffffff",
                              }}
                            >
                              COVERAGE (days)
                            </li>
                          </ul>
                        </div>
                      </Tooltip>
                    </Stack>
                    <div style={parentDivStyle()}>
                      <div style={{ width: "100%" }} ref={treeRef}>
                        {/* className="tree ml-2" */}
                        <ul style={ulStyle()} className={stickyHeaderClassName}>
                          {/* <ul style={ulStyle()} className={`customizer-buttons ${!showSave || !showError ? "sticky-table-header" : ""}`}> */}
                          <li style={liStyle()}>
                            <div
                              style={treeSpanStyle()}
                              className="treeParentSpanStyle"
                            >
                              Tree Node Id
                            </div>
                            {/* <div style={spanStyle()} className="leftBorder"></div> */}
                            <div style={spanStyle()}>
                              Current Quality Stock Outbound
                            </div>
                            <div style={spanStyle()}>
                              Quality Stock Outbound to be
                            </div>
                            <div
                              style={leftBorderSpanStyle()}
                              className="leftBorder"
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  height: "625px",
                                  borderLeft: "2px solid #f5f5f5",
                                  left: "0",
                                  top: "0",
                                }}
                              ></span>
                            </div>
                            <div style={spanStyle()}>
                              Current Quality Stock Inbound
                            </div>
                            <div style={spanStyle()}>
                              Quality Stock Inbound to be
                            </div>
                            <div
                              style={leftBorderSpanStyle()}
                              className="leftBorder"
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  height: "625px",
                                  borderLeft: "2px solid #f5f5f5",
                                  left: "0",
                                  top: "0",
                                }}
                              ></span>
                            </div>
                            <div style={spanStyle()}>Current Safety Stock</div>
                            <div style={spanStyle()}>Safety Stock to be</div>
                            <div
                              style={leftBorderSpanStyle()}
                              className="leftBorder"
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  height: "625px",
                                  borderLeft: "2px solid #f5f5f5",
                                  left: "0",
                                  top: "0",
                                }}
                              ></span>
                            </div>
                            <div style={spanStyle()}>Current Cycle Stock</div>
                            <div style={spanStyle()}>Cycle Stock to be</div>
                            <div
                              style={leftBorderSpanStyle()}
                              className="leftBorder"
                            >
                              {" "}
                              <span
                                style={{
                                  position: "absolute",
                                  height: "625px",
                                  borderLeft: "2px solid #f5f5f5",
                                  left: "0",
                                  top: "0",
                                }}
                              ></span>
                            </div>
                            <div style={spanStyle()}>
                              Current Aggregated Price
                            </div>
                            <div
                              style={{ display: "none" }}
                              className="rightBoder"
                            >
                              Price to be
                            </div>
                          </li>
                        </ul>

                        {parentNode != undefined && parentNode.length > 0 ? (
                          <ul
                            id="Parent"
                            style={ulStyle()}
                            className="tree pl-2"
                          >
                            {LoadParent(parentNode, normUom)}
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorDailog
        message={errMsg}
        showErrorModal={showErrorModal}
        show={showError}
      />
      <SaveModal
        message={saveMsg}
        showSaveModal={showSaveModal}
        show={showSave}
        saveCustom={saveCustom}
      />
    </div>
  );
};
export default CustomizeNormContainer;

const InnerItem = ({
  treeId,
  itemD,
  handleSetItemChanges,
  normUom = 0,
  handleAddNodesEdited,
}) => {
  const spanStyle = () => ({
    width: "12rem",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    fontFamily: "Lato-Medium",
    fontSize: "1.4rem",
    justifyContent: "end",
    position: "relative",
  });

  const onDataChange = (e, targetId, node) => {
    if (node == "gi") {
      // debugger
      normUom == 2
        ? (itemD.gi_tobe_day = e.target.value)
        : normUom == 1
        ? (itemD.gi_tobe_qy = e.target.value)
        : (itemD.gi_tobe = e.target.value);
    } else if (node == "gr") {
      normUom == 2
        ? (itemD.gr_tobe_day = e.target.value)
        : normUom == 1
        ? (itemD.gr_tobe_qy = e.target.value)
        : (itemD.gr_tobe = e.target.value);
      // itemD.gr_tobe = e.target.value;
    } else if (node == "safety") {
      // itemD.safety_tobe = e.target.value;
      normUom == 2
        ? (itemD.safety_tobe_day = e.target.value)
        : normUom == 1
        ? (itemD.safety_tobe_qy = e.target.value)
        : (itemD.safety_tobe = e.target.value);
    } else if (node == "cycle") {
      normUom == 2
        ? (itemD.cycle_tobe_day = e.target.value)
        : normUom == 1
        ? (itemD.cycle_tobe_qy = e.target.value)
        : (itemD.cycle_tobe = e.target.value);
      // itemD.cycle_tobe = e.target.value;
    } else if (node == "price") {
      itemD.price_tobe = e.target.value;
    }
    handleSetItemChanges(itemD, treeId);
    handleAddNodesEdited(itemD, treeId);
  };

  const changeLock = (e, id, flagName, treeId, nodeName) => {
    if (itemD[flagName] === "false" || itemD[flagName] === false) {
      document.getElementById(id).disabled = true;
      e.target.classList.remove("unlock");
      e.target.className = "lock";
      document.getElementById(flagName + "_" + treeId).innerHTML = true;

      itemD[flagName] = true;
    } else if (itemD[flagName]) {
      document.getElementById(id).disabled = false;
      e.target.classList.remove("lock");
      e.target.className = "unlock";
      document.getElementById(flagName + "_" + treeId).innerHTML = false;
      itemD[flagName] = false;
    }

    handleSetItemChanges(itemD, treeId);
    handleAddNodesEdited(itemD, treeId);
  };
  const context = React.useContext(pfContext);

  return (
    <>
      <span style={spanStyle()}>
        <Input
          type="text"
          name="current_gi"
          id={`current_gi_${treeId}`}
          value={
            normUom === 2
              ? itemD.current_gi_day
              : normUom === 1
              ? itemD.current_gi_qy
              : itemD.current_gi
          }
          className="inputStyle"
          readOnly
          disabled
          endAdornment={
            <InputAdornment position="end">
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.unlock}
                  </span>
                }
                open={
                  context.showTooltips &&
                  context.buttonHoverStates === `current_gi_${treeId}`
                }
              >
                <IconButton
                  style={{ padding: 0 }}
                  className={"unlock"}
                  onMouseEnter={() =>
                    context.setButtonHoverStates(`current_gi_${treeId}`)
                  }
                  onMouseLeave={() => context.setButtonHoverStates("")}
                />
              </Tooltip>
            </InputAdornment>
          }
        />
        <input
          type="hidden"
          id={`current_gi_v_${treeId}`}
          value={itemD.current_gi}
        />
        <input
          type="hidden"
          id={`current_gi_qy_${treeId}`}
          value={itemD.current_gi_qy}
        />
        <input
          type="hidden"
          id={`current_gi_day_${treeId}`}
          value={itemD.current_gi_day}
        />
        <span
          id={`current_gi_flag_${treeId}`}
          style={{ visibility: "hidden", position: "absolute" }}
        >
          {itemD.current_gi_flag}
        </span>
        <span
          id={`current_gi_tobe_${treeId}`}
          style={{ visibility: "hidden", position: "absolute" }}
        >
          {itemD.gi_tobe}
        </span>
      </span>
      <span style={spanStyle()}>
        <Input
          type="text"
          style={{
            backgroundColor:
              itemD.user_node_gi == "true" ? "#ffff0073" : "white",
          }}
          id={`current_Git_${treeId}`}
          onChange={(e) => onDataChange(e, `current_gi_tobe_${treeId}`, "gi")}
          value={
            normUom === 2
              ? itemD.gi_tobe_day
              : normUom === 1
              ? itemD.gi_tobe_qy
              : itemD.gi_tobe
          }
          className="inputStyle"
          disabled={itemD.current_gi_flag}
          endAdornment={
            <InputAdornment
              position="end"
              onClick={(e) =>
                changeLock(
                  e,
                  `current_Git_${treeId}`,
                  `current_gi_flag`,
                  treeId,
                  "gi"
                )
              }
            >
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.lockUnlock}
                  </span>
                }
                open={
                  context.showTooltips &&
                  context.buttonHoverStates === `current_Git_${treeId}`
                }
              >
                <IconButton
                  style={{ padding: 0 }}
                  className={itemD.current_gi_flag ? "lock" : "unlock"}
                  onMouseEnter={() =>
                    context.setButtonHoverStates(`current_Git_${treeId}`)
                  }
                  onMouseLeave={() => context.setButtonHoverStates("")}
                />
              </Tooltip>
            </InputAdornment>
          }
        />
      </span>

      <span style={spanStyle()}>
        <Input
          type="text"
          name="current_gr"
          id={`current_gr_${treeId}`}
          value={
            normUom === 2
              ? itemD.current_gr_day
              : normUom === 1
              ? itemD.current_gr_qy
              : itemD.current_gr
          }
          className="inputStyle"
          readOnly
          disabled
          endAdornment={
            <InputAdornment position="end">
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.unlock}
                  </span>
                }
                open={
                  context.showTooltips &&
                  context.buttonHoverStates === `current_gr_${treeId}`
                }
              >
                <IconButton
                  style={{ padding: 0 }}
                  className={"unlock"}
                  onMouseEnter={() =>
                    context.setButtonHoverStates(`current_gr_${treeId}`)
                  }
                  onMouseLeave={() => context.setButtonHoverStates("")}
                />
              </Tooltip>
            </InputAdornment>
          }
        />
        <input
          type="hidden"
          id={`current_gr_v_${treeId}`}
          value={itemD.current_gr}
        />
        <input
          type="hidden"
          id={`current_gr_qy_${treeId}`}
          value={itemD.current_gr_qy}
        />
        <input
          type="hidden"
          id={`current_gr_day_${treeId}`}
          value={itemD.current_gr_day}
        />
        <span
          id={`current_gr_flag_${treeId}`}
          style={{ visibility: "hidden", position: "absolute" }}
        >
          {itemD.current_gr_flag}
        </span>

        <span
          id={`current_gr_tobe_${treeId}`}
          style={{ visibility: "hidden", position: "absolute" }}
        >
          {itemD.gr_tobe}
        </span>
      </span>
      <span style={spanStyle()}>
        <Input
          type="text"
          name="gr_tobe"
          id={`current_Grt_${treeId}`}
          style={{
            backgroundColor:
              itemD.user_node_gr == "true" ? "#ffff0073" : "white",
          }}
          disabled={itemD.current_gr_flag}
          // value={itemD.gr_tobe}
          value={
            normUom === 2
              ? itemD.gr_tobe_day
              : normUom === 1
              ? itemD.gr_tobe_qy
              : itemD.gr_tobe
          }
          onChange={(e) => onDataChange(e, `current_gr_tobe_${treeId}`, "gr")}
          className="inputStyle"
          endAdornment={
            <InputAdornment
              position="end"
              onClick={(e) =>
                changeLock(
                  e,
                  `current_Grt_${treeId}`,
                  `current_gr_flag`,
                  treeId,
                  "gr"
                )
              }
            >
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.lockUnlock}
                  </span>
                }
                open={
                  context.showTooltips &&
                  context.buttonHoverStates === `current_Grt_${treeId}`
                }
              >
                <IconButton
                  style={{ padding: 0 }}
                  className={itemD.current_gr_flag ? "lock" : "unlock"}
                  onMouseEnter={() =>
                    context.setButtonHoverStates(`current_Grt_${treeId}`)
                  }
                  onMouseLeave={() => context.setButtonHoverStates("")}
                />
              </Tooltip>
            </InputAdornment>
          }
        />
      </span>

      <span style={spanStyle()}>
        <Input
          type="text"
          name="current_safety"
          id={`current_safety_${treeId}`}
          value={
            normUom === 2
              ? itemD.current_safety_day
              : normUom === 1
              ? itemD.current_safety_qy
              : itemD.current_safety
          }
          className="inputStyle"
          readOnly
          disabled
          endAdornment={
            <InputAdornment position="end">
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.unlock}
                  </span>
                }
                open={
                  context.showTooltips &&
                  context.buttonHoverStates === `current_safety_${treeId}`
                }
              >
                <IconButton
                  style={{ padding: 0 }}
                  className={"unlock"}
                  onMouseEnter={() =>
                    context.setButtonHoverStates(`current_safety_${treeId}`)
                  }
                  onMouseLeave={() => context.setButtonHoverStates("")}
                ></IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
        <input
          type="hidden"
          id={`current_safety_v_${treeId}`}
          value={itemD.current_safety}
        />
        <input
          type="hidden"
          id={`current_safety_qy_${treeId}`}
          value={itemD.current_safety_qy}
        />
        <input
          type="hidden"
          id={`current_safety_day_${treeId}`}
          value={itemD.current_safety_day}
        />

        <span
          id={`current_safety_flag_${treeId}`}
          style={{ visibility: "hidden", position: "absolute" }}
        >
          {itemD.current_safety_flag}
        </span>

        <span
          id={`current_safety_tobe_${treeId}`}
          style={{ visibility: "hidden", position: "absolute" }}
        >
          {itemD.safety_tobe}
        </span>
      </span>
      <span style={spanStyle()}>
        <Input
          type="text"
          name="safety_tobe"
          style={{
            backgroundColor:
              itemD.user_node_safety == "true" ? "#ffff0073" : "white",
          }}
          id={`current_Sft_${treeId}`}
          // value={itemD.safety_tobe}
          value={
            normUom === 2
              ? itemD.safety_tobe_day
              : normUom === 1
              ? itemD.safety_tobe_qy
              : itemD.safety_tobe
          }
          onChange={(e) =>
            onDataChange(e, `current_safety_tobe_${treeId}`, "safety")
          }
          className="inputStyle"
          disabled={itemD.current_safety_flag}
          endAdornment={
            <InputAdornment
              position="end"
              onClick={(e) =>
                changeLock(
                  e,
                  `current_Sft_${treeId}`,
                  `current_safety_flag`,
                  treeId,
                  "safety"
                )
              }
            >
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.lockUnlock}
                  </span>
                }
                open={
                  context.showTooltips &&
                  context.buttonHoverStates === `current_Sft_${treeId}`
                }
              >
                <IconButton
                  style={{ padding: 0 }}
                  className={itemD.current_safety_flag ? "lock" : "unlock"}
                  onMouseEnter={() =>
                    context.setButtonHoverStates(`current_Sft_${treeId}`)
                  }
                  onMouseLeave={() => context.setButtonHoverStates("")}
                />
              </Tooltip>
            </InputAdornment>
          }
        />
      </span>

      <span style={spanStyle()}>
        <Input
          type="text"
          name="current_cycle"
          id={`current_cycle_${treeId}`}
          value={
            normUom === 2
              ? itemD.current_cycle_day
              : normUom === 1
              ? itemD.current_cycle_qy
              : itemD.current_cycle
          }
          className="inputStyle"
          readOnly
          disabled
          endAdornment={
            <InputAdornment position="end">
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.unlock}
                  </span>
                }
                open={
                  context.showTooltips &&
                  context.buttonHoverStates === `current_cycle_${treeId}`
                }
              >
                <IconButton
                  style={{ padding: 0 }}
                  className={"unlock"}
                  onMouseEnter={() =>
                    context.setButtonHoverStates(`current_cycle_${treeId}`)
                  }
                  onMouseLeave={() => context.setButtonHoverStates("")}
                ></IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
        <input
          type="hidden"
          id={`current_cycle_v_${treeId}`}
          value={itemD.current_cycle}
        />
        <input
          type="hidden"
          id={`current_cycle_qy_${treeId}`}
          value={itemD.current_cycle_qy}
        />
        <input
          type="hidden"
          id={`current_cycle_day_${treeId}`}
          value={itemD.current_cycle_day}
        />
        <span
          id={`current_cycle_flag_${treeId}`}
          style={{ visibility: "hidden", position: "absolute" }}
        >
          {itemD.current_cycle_flag}
        </span>

        <span
          id={`current_cycle_tobe_${treeId}`}
          style={{ visibility: "hidden", position: "absolute" }}
        >
          {itemD.cycle_tobe}
        </span>
      </span>
      <span style={spanStyle()}>
        <Input
          type="text"
          disabled={itemD.current_cycle_flag}
          name="cycle_tobe"
          style={{
            backgroundColor:
              itemD.user_node_cycle == "true" ? "#ffff0073" : "white",
          }}
          // value={itemD.cycle_tobe}
          value={
            normUom === 2
              ? itemD.cycle_tobe_day
              : normUom === 1
              ? itemD.cycle_tobe_qy
              : itemD.cycle_tobe
          }
          className="inputStyle"
          id={`current_Cyt_${treeId}`}
          onChange={(e) =>
            onDataChange(e, `current_cycle_tobe_${treeId}`, "cycle")
          }
          endAdornment={
            <InputAdornment
              position="end"
              onClick={(e) =>
                changeLock(
                  e,
                  `current_Cyt_${treeId}`,
                  `current_cycle_flag`,
                  treeId,
                  "cycle"
                )
              }
            >
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.lockUnlock}
                  </span>
                }
                open={
                  context.showTooltips &&
                  context.buttonHoverStates === `current_Cyt_${treeId}`
                }
              >
                <IconButton
                  style={{ padding: 0 }}
                  className={itemD.current_cycle_flag ? "lock" : "unlock"}
                  onMouseEnter={() =>
                    context.setButtonHoverStates(`current_Cyt_${treeId}`)
                  }
                  onMouseLeave={() => context.setButtonHoverStates("")}
                />
              </Tooltip>
            </InputAdornment>
          }
        />
      </span>

      <span style={spanStyle()}>
        <Input
          type="text"
          id={`current_price_${treeId}`}
          name="current_price"
          value={itemD.current_price}
          className="inputStyle"
          readOnly
          disabled
          endAdornment={
            <InputAdornment position="end">
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.unlock}
                  </span>
                }
                open={
                  context.showTooltips &&
                  context.buttonHoverStates === `current_price_${treeId}`
                }
              >
                <IconButton
                  style={{ padding: 0 }}
                  className={"unlock"}
                  onMouseEnter={() =>
                    context.setButtonHoverStates(`current_price_${treeId}`)
                  }
                  onMouseLeave={() => context.setButtonHoverStates("")}
                />
              </Tooltip>
            </InputAdornment>
          }
        />

        <span
          id={`current_price_flag_${treeId}`}
          style={{ visibility: "hidden", position: "absolute" }}
        >
          {itemD.current_price_flag}
        </span>

        <span
          id={`current_price_tobe_${treeId}`}
          style={{ visibility: "hidden", position: "absolute" }}
        >
          {itemD.current_price}
        </span>
      </span>
      <span style={{ display: "none" }} className="rightBoder">
        <Input
          type="text"
          disabled={itemD.current_price_flag}
          name="price_tobe"
          id={`current_Prt_${treeId}`}
          value={itemD.price_tobe}
          onChange={(e) =>
            onDataChange(e, `current_price_tobe_${treeId}`, "price")
          }
          className="inputStyle"
          endAdornment={
            <InputAdornment
              position="end"
              onClick={(e) =>
                changeLock(
                  e,
                  `current_Prt_${treeId}`,
                  `current_price_flag`,
                  treeId,
                  "price"
                )
              }
            >
              <Tooltip
                title={
                  <span style={{ fontSize: "16px" }}>
                    {tooltipsContent.lockUnlock}
                  </span>
                }
                open={
                  context.showTooltips &&
                  context.buttonHoverStates === `current_Prt_${treeId}`
                }
              >
                <IconButton
                  style={{ padding: 0 }}
                  className={itemD.current_price_flag ? "lock" : "unlock"}
                  onMouseEnter={() =>
                    context.setButtonHoverStates(`current_Prt_${treeId}`)
                  }
                  onMouseLeave={() => context.setButtonHoverStates("")}
                />
              </Tooltip>
            </InputAdornment>
          }
        />
      </span>
    </>
  );
};
