import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useStorageLocation";
import { useMsal } from '@azure/msal-react';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useLocalStorage("user", null);
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  const isAuthenticated = accounts.length > 0;

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);

    if(isAuthenticated)
    {
    if(data.access && data.email.trim().toLowerCase()===accounts[0].username.toLowerCase())
         navigate("/finder");
      else
      navigate("/", { replace: true });

    }
    else
    navigate("/", { replace: true });


  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  try{
  return useContext(AuthContext);
  }
  catch(error)
  {
    console.log("Error in Auth");
  }
};